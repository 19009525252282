import './incidentSection5.module.css';

import {
	ChevronRightIcon,
	ClockIcon,
	TrashIcon,
	UploadIcon,
} from '@heroicons/react/outline';
import Button from 'components/atoms/Button';
import Divider from 'components/atoms/Divider';
import Heading from 'components/atoms/Heading';
import Input from 'components/atoms/Input';
import { IMultiSelectComboBoxOption } from 'components/atoms/MultiSelectComboBox/MultiSelectComboBox';
import Radio from 'components/atoms/Radio';
import Text from 'components/atoms/Text';
import TextareaInput from 'components/atoms/TextareaInput';
import ToggleButton from 'components/atoms/ToggleButton';
import React, { useCallback, useEffect, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import {
	useGetIncidentSection5Mutation,
	useUpdateIncidentSection5Mutation,
	useUploadFileMutation,
} from 'redux/api/vehicleIncidents';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { closeIncidentModal } from 'redux/slices/vehicleIncidentsSlice';
import {
	IOption,
	ISection1ErrorStates,
	ISection2ErrorStates,
	ISection3ErrorStates,
	ISection4ErrorStates,
	ISection5ErrorStates,
	ITabState,
	IVehicleIncidentSection1,
	IVehicleIncidentSection2,
	IVehicleIncidentSection3,
	IVehicleIncidentSection4,
	IVehicleIncidentSection5,
	section5PercentageRadioButtons,
	IQueryReportSection,
} from 'types/VehicleIncidents';
import { disableWriteAccess } from 'utils/disableWriteAccess';
import { removeSeconds } from 'utils/removeSeconds';
import ComboBox from 'components/atoms/ComboBox';
import {
	FaFilePdf,
	FaFileWord,
	FaFileCsv,
	FaFileExcel,
	FaFileImage,
	FaFileAlt,
	FaFileAudio,
	FaFileVideo,
	FaFilePowerpoint,
} from 'react-icons/fa';

export interface IIncidentSection5 {
	sectionId: number | null;
	getIncidents: (filters: string) => void;
	filters: string;
	completed: boolean;
	setSection: React.Dispatch<React.SetStateAction<IQueryReportSection>>;
	setErrorTabStates: React.Dispatch<React.SetStateAction<ITabState>>;
	incidentSection1: IVehicleIncidentSection1;
	incidentSection2: IVehicleIncidentSection2;
	incidentSection3: IVehicleIncidentSection3;
	incidentSection4: IVehicleIncidentSection4;
	section1ErrorStates: ISection1ErrorStates;
	section2ErrorStates: ISection2ErrorStates;
	section3ErrorStates: ISection3ErrorStates;
	section4ErrorStates: ISection4ErrorStates;
}
interface FileResponse extends File {
	id: string;
	url: string;
	ext: string;
}

interface ISection5InitialState {
	percentageShiftWorked: string;
	shiftStartTime: string;
	organisationalFactorsInput: string;
	organisationalFactors2Input: string;
	individualFactorsInput: string;
	absentDefenseInput: string;
	investigator: string;
	users: IMultiSelectComboBoxOption[] | { id: string; label: string }[];
	attachments: FileResponse[] | File[];
}

const initialState: ISection5InitialState = {
	percentageShiftWorked: '',
	shiftStartTime: '',
	organisationalFactorsInput: '',
	organisationalFactors2Input: '',
	individualFactorsInput: '',
	absentDefenseInput: '',
	investigator: '',
	users: [],
	attachments: [],
};

const initialErrorState: ISection5ErrorStates = {
	section_5_shift_start_time: false,
	section_5_investigator: false,
};

const FileIcon: React.FC<{ ext: string }> = ({ ext }) => {
	const fileType = ext.toLowerCase();

	switch (fileType) {
		case '.pdf':
			return <FaFilePdf size="1.5em" style={{ color: 'red' }} />;
		case '.docx':
			return <FaFileWord size="1.5em" style={{ color: 'blue' }} />;
		case '.csv':
			return <FaFileCsv size="1.5em" style={{ color: 'green' }} />;
		case '.xlsx':
			return <FaFileExcel size="1.5em" style={{ color: 'orange' }} />;
		case '.png':
		case '.jpg':
		case '.jpeg':
			return <FaFileImage size="1.5em" style={{ color: 'purple' }} />;
		case '.txt':
			return <FaFileAlt size="1.5em" style={{ color: 'yellow' }} />;
		case '.mp3':
			return <FaFileAudio size="1.5em" style={{ color: 'cyan' }} />;
		case '.mp4':
			return <FaFileVideo size="1.5em" style={{ color: 'magenta' }} />;
		case '.pptx':
			return <FaFilePowerpoint size="1.5em" style={{ color: 'brown' }} />;
		default:
			return null;
	}
};
const IncidentSection5: React.FC<IIncidentSection5> = ({
	sectionId,
	getIncidents,
	filters,
	setErrorTabStates,
	completed,
	setSection,
	section1ErrorStates,
	section2ErrorStates,
	section3ErrorStates,
	section4ErrorStates,
	incidentSection1,
	incidentSection2,
	incidentSection3,
	incidentSection4,
}) => {
	const [isInvestigation, setIsInvestigation] = useState(true);
	const [section5, setSection5] = useState(initialState);
	const [uploadFileLoader, setUploadFileLoader] = useState(false);
	const [errorMessages, setErrorMessages] = useState<string[]>([]);
	const [section5ErrorState, setSection5ErrorState] =
		useState(initialErrorState);

	function isFileResponse(file: File | FileResponse): file is FileResponse {
		return 'id' in file && 'url' in file && 'ext' in file;
	}

	const [getIncidentSection5, { data, isSuccess }] =
		useGetIncidentSection5Mutation();
	const [updateIncidentSection5] = useUpdateIncidentSection5Mutation();

	const dispatch = useAppDispatch();
	const incidentSection1Id = useAppSelector(
		(state) => state.vehicleIncident.incidentSection1Id
	);
	const incidentReportId = useAppSelector(
		(state) => state.vehicleIncident.incidentReportId
	);
	const incidentBranchId = useAppSelector(
		(state) => state.vehicleIncident.incidentBranchId
	);
	const userPermission = useAppSelector((state) => state.user.permissions);

	const disableIncidentWriteAccess = disableWriteAccess(
		'incident_level',
		userPermission,
		incidentBranchId
	);

	useEffect(() => {
		if (sectionId !== null) {
			getIncidentSection5(sectionId);
		}
	}, [getIncidentSection5, sectionId]);

	const [uploadFile] = useUploadFileMutation();

	const [attachments, setAttachments] = useState<FileResponse[] | File[]>([]);

	const handleFileUpload = async (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		if (event.target.files && event.target.files.length > 0) {
			const file = event.target.files[0];
			const formData = new FormData();
			formData.append('files', file);

			try {
				setUploadFileLoader(true);
				const uploadResult = await uploadFile(formData).unwrap();
				const uploadedFile = uploadResult[0];
				setAttachments([...attachments, uploadedFile]);
				updatePayload(false, [...attachments, uploadedFile]);
			} catch (error) {
				console.error('File upload fail', error);
			} finally {
				setUploadFileLoader(false);
				event.target.value = '';
			}
		}
	};

	const handleDeleteFile = async (fileId: string | number) => {
		try {
			const updatedAttachments = attachments.filter(
				(attachment) => isFileResponse(attachment) && attachment.id !== fileId
			);
			setAttachments(updatedAttachments);
			updatePayload(false, updatedAttachments);
		} catch (error) {
			console.error('Delete file fail', error);
		}
	};

	useEffect(() => {
		if (isSuccess && data) {
			const {
				percentage_of_shift_worked,
				incident_investigation_required,
				shift_start_time,
				organisational_factors,
				task_environmental_factors,
				individual_factors,
				absent_failed_defense,
				users,
				investigator,
				attachments,
			} = data.data;

			let selectedPercentageRadioButton = percentage_of_shift_worked;
			section5PercentageRadioButtons.forEach((item) => {
				if (item.title === percentage_of_shift_worked) {
					selectedPercentageRadioButton = item.id;
				}
			});

			const formatShiftStartTime = removeSeconds(shift_start_time);

			setSection5({
				...section5,
				percentageShiftWorked: selectedPercentageRadioButton,
				shiftStartTime: formatShiftStartTime,
				organisationalFactorsInput: organisational_factors || '',
				organisationalFactors2Input: task_environmental_factors || '',
				individualFactorsInput: individual_factors || '',
				absentDefenseInput: absent_failed_defense || '',
				users,
				investigator,
				attachments,
			});

			setIsInvestigation(incident_investigation_required);

			setSection5ErrorState({
				section_5_shift_start_time: !!shift_start_time,
				section_5_investigator: !!investigator,
			});
		}
		// eslint-disable-next-line
	}, [isSuccess, data]);

	const handleSection5Update = (
		property: string,
		value: string | boolean | Date | IOption[],
	) => {
		const newSection5 = section5;
		// @ts-ignore
		newSection5[property] = value;
		setSection5(newSection5);
	};

	const validateSections = useCallback(
		(
			section:
				| ISection2ErrorStates
				| ISection1ErrorStates
				| ISection3ErrorStates
				| ISection4ErrorStates
				| ISection5ErrorStates
		) => {
			const errorArray: string[] = [];
			for (const [key, value] of Object.entries(section)) {
				if (!value) {
					errorArray.push(`${key.replaceAll('_', ' ')}`);
				}
			}
			return errorArray;
		},
		[]
	);

	const validateSection1 = useCallback(
		(section: ISection1ErrorStates) => {
			if (!incidentSection1.thirdParty) {
				return validateSections(section);
			} else {
				const section1Errors = validateSections(section);
				incidentSection1?.thirdParties?.forEach((thirdParty, index) => {
					if (!thirdParty.thirdParty_name) {
						section1Errors.push(`section 1 third party ${index + 1} name`);
					}
					if (!thirdParty.thirdParty_vehicle_registration) {
						section1Errors.push(
							`section 1 third party ${index + 1} vehicle registration`
						);
					}
					if (!thirdParty.thirdParty_licence_number) {
						section1Errors.push(
							`section 1 third party ${index + 1} licence number`
						);
					}
					if (!thirdParty.thirdParty_phone_number) {
						section1Errors.push(
							`section 1 third party ${index + 1} phone number`
						);
					}
				});

				return section1Errors;
			}
		},
		[
			incidentSection1.thirdParty,
			incidentSection1.thirdParties,
			validateSections,
		]
	);

	const validateSection2 = useCallback(
		(section: ISection2ErrorStates) => {
			if (
				!incidentSection2.witness_exists &&
				incidentSection2?.personInvolved?.length <= 0
			) {
				return validateSections(section);
			} else {
				const section2Errors = validateSections(section);
				if (incidentSection2.witness_exists) {
					incidentSection2.witnesses.forEach((witness, index) => {
						if (!witness.witness_name) {
							section2Errors.push(`section 2 witness ${index + 1} name`);
						}
						if (!witness.witness_phone_number) {
							section2Errors.push(
								`section 2 witness ${index + 1} phone number`
							);
						}
					});
				}
				if (incidentSection2?.personInvolved?.length > 0) {
					incidentSection2.personInvolved.forEach((person, index) => {
						if (!person.personInvolved_name) {
							section2Errors.push(
								`section 2 person involved ${index + 1} name`
							);
						}
						if (!person.personInvolved_phone_number) {
							section2Errors.push(
								`section 2 person involved ${index + 1} phone number`
							);
						}
					});
				}
				return section2Errors;
			}
		},
		[
			incidentSection2.witness_exists,
			incidentSection2.witnesses,
			incidentSection2.personInvolved,
			validateSections,
		]
	);

	const validateSection3 = useCallback(
		(section: ISection3ErrorStates) => {
			// if (!incidentSection3.nuisance_report && incidentSection3.injuries) {
			if (incidentSection3.injuries) {
				return validateSections(section);
			} else {
				return [];
			}
		},
		[
			// incidentSection3.nuisance_report,
			incidentSection3.injuries,
			validateSections,
		]
	);

	const validateSection4 = useCallback(
		(section: ISection4ErrorStates) => {
			const errorArray: string[] = [];
			for (const [key, value] of Object.entries(section)) {
				if (
					(key === 'section_4_reference_number' ||
						key === 'section_4_reported_to') &&
					incidentSection4.isNotifiableIncident &&
					!value
				) {
					errorArray.push(`${key.replaceAll('_', ' ')}`);
				} else if (
					key === 'section_4_life_saving_rules' &&
					incidentSection4.isLifeSavingBreach &&
					!value
				) {
					errorArray.push(`${key.replaceAll('_', ' ')}`);
				} else if (
					key === 'section_4_code_of_conduct_checkbox' &&
					incidentSection4.isCodeOfConductBreach &&
					!value
				) {
					errorArray.push(`${key.replaceAll('_', ' ')}`);
				} else if (
					key === 'section_4_incident_agency' &&
					!incidentSection3.injuries &&
					!value
				) {
					errorArray.push(`${key.replaceAll('_', ' ')}`);
				} else if (
					key === 'section_4_incident_mechanism' &&
					!incidentSection3.injuries &&
					!value
				) {
					errorArray.push(`${key.replaceAll('_', ' ')}`);
				} else if (key === 'section_4_incident_category' && !value) {
					errorArray.push(`${key.replaceAll('_', ' ')}`);
				}
			}
			return errorArray;
		},
		[
			incidentSection4.isNotifiableIncident,
			incidentSection4.isLifeSavingBreach,
			incidentSection4.isCodeOfConductBreach,
			incidentSection3.injuries,
		]
	);

	const validateSection5 = useCallback(
		(section: ISection5ErrorStates) => {
			const section5ErrorArray: string[] = [];

			if (isInvestigation) {
				return validateSections(section);
			}

			for (const [key, value] of Object.entries(section)) {
				if (key === 'section_5_investigator' && isInvestigation && !value) {
					section5ErrorArray.push(`${key.replaceAll('_', ' ')}`);
				} else if (key === 'section_5_shift_start_time' && !value) {
					section5ErrorArray.push(`${key.replaceAll('_', ' ')}`);
				} else if (
					key !== ('section_5_shift_start_time' && 'section_5_investigator') &&
					!value
				) {
					section5ErrorArray.push(`${key.replaceAll('_', ' ')}`);
				}
			}

			return section5ErrorArray;
		},
		[isInvestigation, validateSections]
	);

	useEffect(() => {
		const sectionMessages = validateSections(section1ErrorStates);
		const section1Messages = validateSection1(section1ErrorStates);
		const section2Messages = validateSection2(section2ErrorStates);
		const section3Messages = validateSection3(section3ErrorStates);
		const section4Messages = validateSection4(section4ErrorStates);
		const section5Messages = validateSection5(section5ErrorState);

		setErrorMessages([
			...sectionMessages,
			...section1Messages,
			...section2Messages,
			...section3Messages,
			...section4Messages,
			...section5Messages,
		]);

		setErrorTabStates({
			section1: section1Messages.length !== 0,
			section2: section2Messages.length !== 0,
			section3: section3Messages.length !== 0,
			section4: section4Messages.length !== 0,
			section5: section5Messages.length !== 0,
		});
	}, [
		setErrorMessages,
		validateSections,
		validateSection1,
		validateSection2,
		validateSection3,
		validateSection4,
		validateSection5,
		section1ErrorStates,
		section2ErrorStates,
		section3ErrorStates,
		section4ErrorStates,
		section5ErrorState,
		setErrorTabStates,
	]);

	const updatePayload = async (
		isReportFinished?: boolean,
		newAttachments?: FileResponse[] | File[]
	) => {
		const { investigator, shiftStartTime } = section5;

		if (!sectionId) {
			return;
		}

		let percentageOfShiftWorked = section5.percentageShiftWorked;
		section5PercentageRadioButtons.forEach((item) => {
			if (item.id === section5.percentageShiftWorked) {
				percentageOfShiftWorked = item.title;
			}
		});

		const formatShiftStartTime =
			shiftStartTime && shiftStartTime.length < 6
				? `${shiftStartTime}:00.000`
				: shiftStartTime;

		const updatedAttachments = newAttachments ?? attachments;

		const payload: IVehicleIncidentSection5 = {
			id: sectionId,
			percentage_of_shift_worked: percentageOfShiftWorked,
			organisational_factors: section5.organisationalFactorsInput,
			task_environmental_factors: section5.organisationalFactors2Input,
			individual_factors: section5.individualFactorsInput,
			absent_failed_defense: section5.absentDefenseInput,
			incident_investigation_required: isInvestigation,
			investigator,
			users: section5.users,
			shift_start_time: formatShiftStartTime,
			section1Id: incidentSection1Id,
			incidentReportId,
			attachments: updatedAttachments,
		};

		if (isReportFinished) {
			payload.isReportFinished = isReportFinished;
		}

		await updateIncidentSection5(payload);
	};

	useEffect(() => {
		if (section5.attachments && section5.attachments.length > 0) {
			setAttachments(section5.attachments);
		}
	}, [section5.attachments]);

	const handleOnBlur = async () => {
		if (disableIncidentWriteAccess) {
			return;
		}

		await updatePayload();

		setSection5ErrorState({
			section_5_shift_start_time: !!section5.shiftStartTime,
			section_5_investigator: !!section5.investigator,
		});
	};

	const handleCreateIncidentSection5 = async () => {
		if (!sectionId) {
			return;
		}
		await updatePayload(true);
		dispatch(closeIncidentModal());
		getIncidents(filters);
	};

	const handleChangeAndBlur = (
		property: string,
		value: string | boolean | Date | IOption[],
	) => {
		handleSection5Update(property, value);
		handleOnBlur();
	};

	return (
		<>
			<Heading type="h2">Incident Investigation</Heading>
			<ToggleButton
				toggle={isInvestigation}
				setToggle={setIsInvestigation}
				label="Incident Investigation Required"
				onBlur={handleOnBlur}
				isDisabled={disableIncidentWriteAccess}
			/>
			{isInvestigation && (
				<div>
					<ComboBox
						label="Investigator"
						options={section5.users}
						isDisabled={disableIncidentWriteAccess}
						required={true}
						onChange={(value: string) =>
							handleChangeAndBlur('investigator', value)
						}
						selected={section5.investigator}
						className="mb-10 w-80"
					/>
				</div>
			)}

			<Divider />
			<div className="flex flex-row">
				<Input
					type="time"
					onChange={(value: string) =>
						handleSection5Update('shiftStartTime', value)
					}
					onBlur={handleOnBlur}
					value={section5.shiftStartTime}
					className="w-80"
					label="Shift Start Time"
					Icon={ClockIcon}
					iconClassName="text-black"
					iconPosition="trailing"
					placeholder="Select"
					isDisabled={disableIncidentWriteAccess}
				/>
				<div className="pl-16">
					<Text
						type="bold"
						className="block text-base font-bold font-inter text-secondary mb-3.5"
					>
						Portion of Shift Worked when Incident Occurred *
					</Text>
					<Radio
						options={section5PercentageRadioButtons}
						onChange={(value: string) =>
							handleSection5Update('percentageShiftWorked', value)
						}
						onBlur={handleOnBlur}
						defaultId={section5.percentageShiftWorked || '1'}
						radioDirection="flex-row"
						classNameWrapper="gap-4"
						isDisabled={disableIncidentWriteAccess}
					/>
				</div>
			</div>
			<Divider />
			<Heading type="h3">
				What factors contributed to the incident occuring?
			</Heading>
			<div>
				<Text type="bold" className="py-2 mb-2 text-sm">
					Organisational Factors
				</Text>
				<Text className="text-xs" color="text-secondary-300">
					The underlying factors in the organisation that influenced and
					produced the conditions affecting the performance in the workplace.
					Examples: No SOP for a particular task, inadequate training, staff not
					properly inducted, no process to ensure appropriate resources on the
					job, failure of fatigue management process etc.
				</Text>
				<TextareaInput
					id="1"
					className="w-full"
					value={section5.organisationalFactorsInput}
					rows={5}
					cols={35}
					placeholder="Description"
					resize={true}
					onChange={(value: string) =>
						handleSection5Update('organisationalFactorsInput', value)
					}
					onBlur={handleOnBlur}
					isDisabled={disableIncidentWriteAccess}
				/>
			</div>
			<div>
				<Text type="bold" className="py-2 mb-2 text-sm">
					Environmental / Task factors
				</Text>
				<Text className="text-xs" color="text-secondary-300">
					The local workplace factors. The conditions, circumstances or objects
					in the workplace that directly influenced human and equipment
					performance at the time or immediately prior to an incident. Examples:
					Extreme temperatures, poor light, wet weather, bend in road affecting
					visibility, no shoulder for placement of signs etc.
				</Text>
				<TextareaInput
					id="2"
					className="w-full"
					value={section5.organisationalFactors2Input}
					rows={5}
					cols={35}
					placeholder="Description"
					resize={true}
					onChange={(value: string) =>
						handleSection5Update('organisationalFactors2Input', value)
					}
					onBlur={handleOnBlur}
					isDisabled={disableIncidentWriteAccess}
				/>
			</div>
			<div>
				<Text type="bold" className="py-2 mb-2 text-sm">
					Individual / Team Factors
				</Text>
				<Text className="text-xs" color="text-secondary-300">
					The actions, errors, or violations that led directly to the incident.
					Examples: Traffic Controller crossed live lane of traffic; Traffic
					Controller stopping traffic with no Stop Bat or not wearing PPE;
					Member of public on mobile phone driving through site; Machine
					operator not communicating plant movement etc.
				</Text>
				<TextareaInput
					id="3"
					className="w-full"
					value={section5.individualFactorsInput}
					rows={5}
					cols={35}
					placeholder="Description"
					resize={true}
					onChange={(value: string) =>
						handleSection5Update('individualFactorsInput', value)
					}
					onBlur={handleOnBlur}
					isDisabled={disableIncidentWriteAccess}
				/>
			</div>
			<div>
				<Text type="bold" className="py-2 mb-2 text-sm">
					Absent / Failed Defenses
				</Text>
				<Text className="text-xs" color="text-secondary-300">
					The last minute measures which did not prevent the outcome of the
					incident or reduce its consequences. Examples: Hand brake not engaged
					(runaway vehicle); Vehicle protruding into live lane (Vehicle
					incident); TC not wearing steel capped boots (foot injury) etc.
				</Text>
				<TextareaInput
					id="4"
					className="w-full"
					value={section5.absentDefenseInput}
					rows={5}
					cols={35}
					placeholder="Description"
					resize={true}
					onChange={(value: string) =>
						handleSection5Update('absentDefenseInput', value)
					}
					onBlur={handleOnBlur}
					isDisabled={disableIncidentWriteAccess}
				/>
			</div>
			<Divider />
			<div>
				<div className="flex items-center">
					<div className="mr-10">
						<Button className="inline-block font-bold" type="secondary">
							Upload File
							<UploadIcon className="h-5 w-5 ml-2" />
							<input
								type="file"
								onChange={handleFileUpload}
								style={{
									opacity: '0',
									position: 'absolute',
									left: '0',
								}}
							/>
						</Button>
					</div>
					<div className="ml-10">
						<Text type="bold" className="pb-4 text-center">
							Uploaded Files
						</Text>
						{attachments.map((attachment, index) =>
							isFileResponse(attachment) ? (
								<div key={attachment.id} className="flex items-center mb-2">
									<FileIcon ext={attachment.ext} />
									<div
										className="text-base font-inter text-secondary cursor-pointer flex-grow ml-4"
										onClick={() => window.open(attachment.url, '_blank')}
									>
										{attachment.name}
									</div>
									<TrashIcon
										height={25}
										width={25}
										className="cursor-pointer ml-4"
										onClick={() => handleDeleteFile(attachment.id)}
									/>
								</div>
							) : (
								<li
									key={index}
									className="block text-base font-inter text-secondary"
								>
									{attachment.name}
								</li>
							)
						)}
						<>
							{uploadFileLoader ? (
								<TailSpin
									height="25"
									width="25"
									ariaLabel="tail-spin-loading"
									color="#FE9F00"
									wrapperClass="flex mt-2"
									radius={2}
									visible
								/>
							) : null}
						</>
					</div>
				</div>
			</div>

			{errorMessages.length > 0 && (
				<div>
					<Heading type="h3" className="pb-3">
						Required Fields to Create Report
					</Heading>
					{errorMessages.map((error, index) => {
						return (
							<Text key={index} color="text-red-500">
								{error}
							</Text>
						);
					})}
				</div>
			)}
			<div className="h-0 border-t border-primary-200 w-full my-5" />
			<div className="flex justify-between">
				<Button
					onClick={handleCreateIncidentSection5}
					className="font-bold"
					isDisabled={errorMessages.length > 0 || disableIncidentWriteAccess}
				>
					{completed ? 'Save' : 'Create Report'}
					<ChevronRightIcon height={18} width={32}/>
				</Button>
				<div className="flex justify-end">
					<Button
						onClick={() => setSection('section4')}
						type="primary"
						className="font-bold mr-4"
					>
						Back to Analysis
					</Button>
					<Button
						onClick={() => setSection('section7')}
						type="primary"
						className="font-bold mr-4"
					>
						Continue to Corrective Actions
					</Button>
				</div>
			</div>
		</>
	);
};

export default IncidentSection5;
