import {
	AnnotationIcon,
	EyeIcon,
	PencilAltIcon,
	UploadIcon,
} from '@heroicons/react/outline';
import { pdf } from '@react-pdf/renderer';
import Button from 'components/atoms/Button';
import Heading from 'components/atoms/Heading';
import Radio from 'components/atoms/Radio';
import Text from 'components/atoms/Text';
import ToggleButton from 'components/atoms/ToggleButton';
import React, { useEffect, useState } from 'react';
import {
	useAssignIncidentMutation,
	useCreateIncidentActivityMutation,
	useGetIncidentMutation,
} from 'redux/api/vehicleIncidents';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
	openCommentModal,
	openIncidentModal,
} from 'redux/slices/vehicleIncidentsSlice';
import { IVehicleIncident } from 'types/VehicleIncidents';
import { hasAccess } from 'utils/permissions';
import { useGetListQuery as useGetLogoListQuery } from 'redux/api/pdfCompanyLogoAPI';

import PdfDocument from '../PdfDocument';

export interface IIncidentCard {
	incident: IVehicleIncident;
	refetchData: () => void;
}

const IncidentCard: React.FC<IIncidentCard> = ({ incident, refetchData }) => {
	const dispatch = useAppDispatch();

	const [assignIncident] = useAssignIncidentMutation();
	const [isDuplicateOrNuisance, setIsDuplicateOrNuisance] = useState(
		['duplicate', 'nuisance'].includes(incident.special_status || '') &&
			incident.status !== 'open'
	);
	const [specialStatus, setSpecialStatus] = useState<
		'duplicate' | 'nuisance' | null
	>(incident.special_status || null);
	const [status, setStatus] = useState(incident.status || 'open');

	const [createIncidentActivity] = useCreateIncidentActivityMutation();
	const userId = useAppSelector((state) => state.user.id);

	const userPermission = useAppSelector((state) => state.user.permissions);
	const hasWriteAccess = () => {
		return hasAccess(
			userPermission,
			null,
			'incident_level',
			'write',
			incident?.branch.id
		);
	};
	const hasStatusWriteAccess = () => {
		return hasAccess(
			userPermission,
			'admin-manager',
			'incident_level',
			'write',
			incident?.branch.id
		);
	};

	const submitButtonDisabled = () => {
		if (hasStatusWriteAccess()) {
			return (
				incident.status === status && incident.special_status === specialStatus
			);
		} else {
			return (
				(incident.special_status === null && !isDuplicateOrNuisance) ||
				(incident.special_status === specialStatus && isDuplicateOrNuisance)
			);
		}
	};

	const statusChangeVisible = () => {
		return hasStatusWriteAccess();
	};

	const setIsDuplicateOrNuisanceHandler = (dupeOrNuisance: boolean) => {
		setIsDuplicateOrNuisance(dupeOrNuisance);

		if (!dupeOrNuisance) {
			setSpecialStatus(null);
		} else {
			setSpecialStatus('duplicate');
			setStatus('closed');
		}
	};

	const handleOpenComments = () => {
		const payload = {
			commentIncidentId: incident.id,
			incidentBranchId: incident?.branch?.id,
		};
		dispatch(openCommentModal(payload));
	};

	const handleOpenIncidentReport = () => {
		try {
			const payload = {
				incidentReportId: incident.id,
				incidentBranchId: incident.branch.id,
				incidentSection1Id: incident?.incident_section_1?.id,
				incidentSection2Id: incident?.incident_section_2?.id,
				incidentSection3Id: incident?.incident_section_3?.id,
				incidentSection4Id: incident?.incident_section_4?.id,
				incidentSection5Id: incident?.incident_section_5?.id,
				incidentSection7Id: incident?.incident_section_7?.id,
			};
			dispatch(openIncidentModal(payload));
			createIncidentActivity({
				incident: incident.id,
				user: userId,
				type: 'view report',
				content: 'Report opened',
			});
		} catch (error) {
			if (error instanceof Error) {
				throw new Error(error.message);
			}
		}
	};

	const handleUpdateIncident = async () => {
		if (status === 'open') {
			setSpecialStatus(null);
			setIsDuplicateOrNuisance(false);
		}

		await assignIncident({
			id: incident.id,
			body: {
				status: status,
				special_status: isDuplicateOrNuisance ? specialStatus : null,
			},
		});

		refetchData();
	};

	const [
		getIncident,
		{ data: incidentDetail, isSuccess: isSuccessGetIncidentDetail },
	] = useGetIncidentMutation();

	const handleExportIncidentReport = () => {
		getIncident(incident.id);
	};

	const { data: companyLogos, isSuccess: isLogoSuccess } =
		useGetLogoListQuery();

	useEffect(() => {
		if (isSuccessGetIncidentDetail && incidentDetail && isLogoSuccess) {
			pdf(
				PdfDocument({
					incident: incidentDetail.incidentData,
					logos: companyLogos || [],
				})
			)
				.toBlob()
				.then((blob) => {
					const url = window.URL.createObjectURL(blob);
					const tempLink = document.createElement('a');
					tempLink.href = url;
					tempLink.setAttribute('download', 'Incident.pdf');
					tempLink.click();

					createIncidentActivity({
						incident: incident.id,
						user: userId,
						type: 'export',
						content: 'Export report to PDF',
					});
				});
		}
		// eslint-disable-next-line
	}, [incidentDetail, isSuccessGetIncidentDetail]);

	const openIncidentReportDisabled = () => {
		return incident.status === 'closed';
	};

	return (
		<div className="w-full px-10 pb-10 pt-8 border-t border-primary">
			<div className="flex">
				<div className="flex-col w-1/2 xl:w-3/4">
					<Heading type="h2" className="mb-6">
						Overview
					</Heading>
					<div className="flex pr-8 lg:pr-12 2xl:pr-32 justify-between space-x-8 lg:space-x-12 2xl:space-x-32">
						<div className="min-w-[200px]">
							<Heading type="h3" className="mb-6">
								Description
							</Heading>
							<Text className="max-w-lg mb-10 text-sm">
								{incident.description}
							</Text>
							<div className="flex w-[350px]">
								<Text
									className="flex basis-1/2 text-sm mb-2 w-[200px]"
									type="bold"
								>
									Incident Category
								</Text>
								{incident?.incident_section_4?.incident_category.length > 0 ? (
									<div>
										{incident.incident_section_4.incident_category.map(
											(category) => (
												<Text
													key={category.id}
													className="flex basis-1/2 text-sm mb-2"
												>
													{category.label}
												</Text>
											)
										)}
									</div>
								) : (
									<Text className="flex basis-1/2 text-sm mb-2">
										No Category
									</Text>
								)}
							</div>
							<div className="h-0 border-t-[1px] border-primary-200 w-full my-5" />
							<div className="flex w-[350px]">
								<Text
									className="flex basis-1/2 mb-6 text-sm w-[200px]"
									type="bold"
								>
									Client
								</Text>
								<Text className="flex basis-1/2 mb-6 text-sm">
									{incident.client}
								</Text>
							</div>
						</div>

						<div className="hidden xl:flex xl:flex-col">
							<div className="mb-4 space-y-2">
								<Text type="bold">Created:</Text>
								<Text className="text-sm">
									{new Date(incident.submitted_at).toLocaleDateString('en-AU')}
								</Text>
							</div>
							<div className="mb-4 space-y-2">
								<Text type="bold">Last Action:</Text>
								{incident?.incident_activities ? (
									<>
										<Text className="text-sm">
											Name: {incident.incident_activities.name}
										</Text>
										<Text className="text-sm">
											Type: {incident.incident_activities.type}
										</Text>
										<Text className="text-sm">
											Date:{' '}
											{new Date(
												incident.incident_activities.createdAt
											).toLocaleDateString('en-AU')}
										</Text>
									</>
								) : (
									<Text className="text-sm">No Actions</Text>
								)}
							</div>
						</div>
						<div className="hidden xl:flex xl:flex-col">
							<div className="mb-4 space-y-2">
								<Text type="bold">Assigned to:</Text>
								{incident?.assignees?.map((assignee) => (
									<Text key={assignee.id} className="text-sm">
										{assignee.first_name} {assignee.last_name}
									</Text>
								))}
							</div>
							<div className="mb-4 space-y-2">
								<Text type="bold">Person Involved:</Text>
								{incident?.incident_section_2?.personInvolved?.map((person) => (
									<Text className="text-sm">{person.personInvolved_name}</Text>
								))}
							</div>
						</div>
					</div>
				</div>
				<div className="flex flex-1 w-1/2 xl:w-1/4 justify-end">
					<div className="max-w-sm w-full">
						<div className="flex flex-row justify-around items-start">
							<div className="space-y-5 w-1/2 mr-4">
								<Button
									className="font-bold w-full xl:mb-0"
									onClick={handleOpenIncidentReport}
									size="lg"
									isDisabled={openIncidentReportDisabled()}
									rightClickLink={`/incidents?view=${incident.id}`}
								>
									{incident.completed ? (
										<EyeIcon className="h-5 w-5 mr-1" />
									) : (
										<PencilAltIcon className="h-5 w-5 mr-1" />
									)}
									{incident.completed ? 'View Report' : 'Create Report'}
								</Button>
								{incident.completed && (
									<Button
										className="font-bold w-full xl:mb-0"
										onClick={handleExportIncidentReport}
										size="lg"
									>
										<UploadIcon className="h-5 w-5 mr-1" />
										Export to PDF
									</Button>
								)}
							</div>
							<Button
								type="quaternary"
								className="font-bold w-1/2"
								onClick={handleOpenComments}
								size="lg"
							>
								<AnnotationIcon className="h-5 w-5 mr-1" />
								History
							</Button>
						</div>
						{hasWriteAccess() && (
							<>
								<div className="h-0 border-t-[1px] border-primary-200 w-full my-4" />
								<div className="flex flex-row items-center">
									<Text type="bold" className="mr-2">
										Is this a Nuisance or Duplicate Report?
									</Text>
									<ToggleButton
										toggle={isDuplicateOrNuisance}
										onToggle={setIsDuplicateOrNuisanceHandler}
									/>
								</div>
								{isDuplicateOrNuisance && (
									<Radio
										className="mb-7"
										classNameWrapper="space-x-4"
										options={[
											{ title: 'Duplicate', id: 'duplicate' },
											{ title: 'Nuisance Report', id: 'nuisance' },
										]}
										radioDirection="flex-row"
										defaultId={specialStatus || 'duplicate'}
										onChange={setSpecialStatus}
									/>
								)}
								<div className="h-0 border-t-[1px] border-primary-200 w-full my-4" />
								{statusChangeVisible() && (
									<>
										<div className="flex flex-row items-center">
											<Text type="bold" className="mr-2 my-2">
												Incident Status
											</Text>
										</div>
										<Radio
											className="mb-7"
											classNameWrapper="space-x-4"
											options={[
												{ title: 'Open', id: 'open' },
												{ title: 'Closed', id: 'closed' },
											]}
											radioDirection="flex-row"
											defaultId={status || 'open'}
											onChange={setStatus}
										/>
										<div className="h-0 border-t-[1px] border-primary-200 w-full my-4" />
									</>
								)}
								<div className="flex justify-end">
									<Button
										navigate="next"
										onClick={handleUpdateIncident}
										className="font-bold"
										isDisabled={submitButtonDisabled()}
									>
										Submit
									</Button>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
			{/*tidy up component for responsive desgin once data coming from API */}
			<div className="flex justify-between mt-8 w-full xl:hidden">
				<div className="mb-4 space-y-2">
					<Text type="bold">Created:</Text>
					<Text className="text-sm">
						{new Date(incident.submitted_at).toLocaleDateString('en-AU')}
					</Text>
				</div>
				<div className="mb-4 space-y-2">
					<Text type="bold">Last Activity:</Text>
					{incident?.incident_activities ? (
						<>
							<Text className="text-sm">
								Name: {incident.incident_activities.name}
							</Text>
							<Text className="text-sm">
								Type: {incident.incident_activities.type}
							</Text>
							<Text className="text-sm">
								Date:{' '}
								{new Date(
									incident.incident_activities.createdAt
								).toLocaleDateString('en-AU')}
							</Text>
						</>
					) : (
						<Text className="text-sm">No Actions</Text>
					)}
				</div>
				<div className="mb-4 space-y-2">
					<Text type="bold">Assigned to:</Text>
					{incident?.assignees?.map((assignee) => (
						<Text key={assignee.id} className="text-sm">
							{assignee.first_name} {assignee.last_name}
						</Text>
					))}
				</div>
			</div>
		</div>
	);
};

export default IncidentCard;
