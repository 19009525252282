/**
 * Converts a full name to initials, plus last name:
 * John Doe => J. Doe
 *
 * @param {string} name Full name, eg: John Doe
 * @return {string} fist name initialed
 */
export const formatName = (name: string) => {
	if (!name) {
		return '';
	}

	const nameArray = name.split(' ');

	const lastName = nameArray.at(-1) || '';
	nameArray.splice(-1);
	let firstName = '';
	if (nameArray) {
		nameArray.forEach((name) => {
			firstName += `${name[0].toUpperCase()}.`;
		});
	}

	return `${firstName} ${lastName}`;
};
