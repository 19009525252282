import { useEffect } from 'react';
import { IIncident, ISection2ErrorStates } from 'types/VehicleIncidents';
import { IVehicleIncidentSection2 } from 'types/VehicleIncidents';

export const useGetIncidentSection2 = (
	isSuccess: boolean,
	data: IIncident | undefined,
	setIncidentSection2: React.Dispatch<
		React.SetStateAction<IVehicleIncidentSection2>
	>,
	setSection2IncidentErrorStates: React.Dispatch<
		React.SetStateAction<ISection2ErrorStates>
	>
) => {
	useEffect(() => {
		if (isSuccess && data) {
			const { personInvolved, witness_exists, witnesses } =
				data.incidentData.incident_section_2;

			setIncidentSection2((prevState) => ({
				...prevState,
				personInvolved:
					personInvolved && personInvolved.length > 0
						? personInvolved
						: [
								{
									id: 1,
									personInvolved_name: '',
									personInvolved_phone_number: '',
									personInvolved_start_date: new Date(),
									personInvolved_length_of_service: '',
								},
						  ],
				witness_exists,
				witnesses:
					witnesses && witnesses.length > 0
						? witnesses
						: [
								{
									id: 1,
									witness_name: '',
									witness_position: '',
									witness_task: '',
									witness_phone_number: '',
								},
						  ],
			}));
		}
	}, [isSuccess, data, setIncidentSection2, setSection2IncidentErrorStates]);
};
