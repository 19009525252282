import {
	Document,
	Image,
	Page,
	StyleSheet,
	Text,
	View,
} from '@react-pdf/renderer';
import { IIncident } from 'types/VehicleIncidents';
import { formatDateTime } from 'utils/formatDateTime';
import { formatTenure } from 'utils/formatTenure';
import { removeSeconds } from 'utils/removeSeconds';
import { IPdfCompanyLogo } from 'types/PdfCompanyLogo';

export interface IPdfDocument {
	incident: IIncident['incidentData'];
	logos: IPdfCompanyLogo[];
}

type IPdfMapper = {
	heading: string;
	render: (prop: IIncident['incidentData']) => string;
}[];

const SECTION_TITLE = 'SECTION_TITLE';
const SECTION_SEPARATOR = 'SECTION_SEPARATOR';
const SECTION_END = 'SECTION_END';

const styles = StyleSheet.create({
	logo: {
		height: 50,
		width: 200,
	},
	bottomLogo: {
		maxHeight: 50,
		maxWidth: 150,
		width: 75,
		marginLeft: 10,
		marginRight: 10,
	},
	headerContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
	},
	topLogoContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		paddingBottom: 10,
	},
	bottomLogoContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
	},
	separator: {
		borderBottom: 2,
		borderColor: '#fe9f00',
		marginVertical: 10,
	},
	title: {
		fontSize: 20,
		marginHorizontal: 'auto',
		fontWeight: 500,
		fontFamily: 'Helvetica-Bold',
		marginBottom: 10,
	},
	page: {
		flexDirection: 'column',
		backgroundColor: 'white',
		paddingVertical: 20,
		paddingHorizontal: 20,
	},
	sectionTitle: {
		backgroundColor: '#fe9f00',
		width: '100%',
		padding: 5,
	},
	section: {
		flexDirection: 'row',
		width: '100%',
		border: 0,
		borderColor: '#fe9f00',
	},
	sectionSeparator: {
		marginHorizontal: 5,
		borderBottom: 1,
		borderColor: '#fe9f00',
	},
	sectionEnd: {
		borderTop: 1,
		borderColor: '#fe9f00',
		width: '100%',
		flexDirection: 'row',
		marginBottom: 15,
	},
	separatorContainer: {
		borderLeft: 1,
		borderRight: 1,
		borderColor: '#fe9f00',
		display: 'flex',
		width: '100%',
		backgroundColor: '#fff7d9',
	},
	container: {
		paddingVertical: 5,
		paddingHorizontal: 5,
		border: 0,
	},
	keyContainer: {
		borderLeft: 1,
		borderColor: '#fe9f00',
		display: 'flex',
		width: '35%',
		backgroundColor: '#fff7d9',
	},
	textKey: {
		fontSize: 10,
		fontWeight: 500,
		fontFamily: 'Helvetica-Bold',
	},
	valueContainer: {
		borderRight: 1,
		borderColor: '#fe9f00',
		display: 'flex',
		width: '65%',
		backgroundColor: '#fff7d9',
		fontFamily: 'Helvetica',
	},
	textValue: {
		fontSize: 10,
	},
});

const PdfDocument = ({ incident, logos }: IPdfDocument) => {
	const mapper: IPdfMapper = [
		{
			heading: SECTION_TITLE,
			render: () => 'Section 1 - Incident Description',
		},
		{
			heading: 'Branch',
			render: (prop) => prop.incident_section_1.branch,
		},
		{
			heading: 'incident #',
			render: (prop) => prop.id.toString(),
		},
		{
			heading: 'Ticket No',
			render: (prop) => prop.ticket_number,
		},
		{
			heading: 'Incident Date',
			render: (prop) =>
				new Date(prop.incident_section_1.incident_date)
					.toLocaleDateString('en-AU', {
						weekday: 'short',
						day: 'numeric',
						month: 'short',
					})
					.replace(',', ''),
		},
		{
			heading: 'Incident Time',
			render: (prop) => removeSeconds(prop.incident_section_1.incident_time),
		},
		{
			heading: 'Submitted By',
			render: (prop) => prop.submitted_by,
		},
		{
			heading: 'Submitted Date',
			render: (prop) =>
				new Date(prop.submitted_at)
					.toLocaleDateString('en-AU', {
						weekday: 'short',
						day: 'numeric',
						month: 'short',
					})
					.replace(',', ''),
		},
		{
			heading: 'Submitted Time',
			render: (prop) =>
				new Date(prop.submitted_at).toLocaleTimeString('en-AU', {
					hour: '2-digit',
					minute: '2-digit',
					hourCycle: 'h24',
				}),
		},
		{
			heading: 'Client',
			render: (prop) => prop.client,
		},
		{
			heading: 'Severity',
			render: (prop) => prop.severity,
		},
		{
			heading: 'Exact Location',
			render: (prop) => prop.incident_section_1.location,
		},
		{
			heading: 'Job Details',
			render: (prop) => prop.incident_section_1.job_details,
		},
		{
			heading: 'Description',
			render: (prop) => prop.description,
		},
	];

	mapper.push(
		{
			heading: SECTION_SEPARATOR,
			render: () => '',
		},
		{
			heading: 'MVI or MVI3 incident',
			render: (prop) => (prop.incident_section_1.mv_incident ? 'Yes' : 'No'),
		}
	);

	if (incident.incident_section_1.mv_incident) {
		mapper.push(
			{
				heading: 'Altus Registration',
				render: (prop) => prop.incident_section_1.altusRegistration,
			},
			{
				heading: 'Name of driver',
				render: (prop) => prop.incident_section_1.driverName,
			},
			{
				heading: 'Licence # & Class',
				render: (prop) => prop.incident_section_1.licenseClass,
			},
			{
				heading: 'Licence Expiry',
				render: (prop) => prop.incident_section_1.licenseExpiry,
			}
		);
	}

	mapper.push(
		{
			heading: SECTION_END,
			render: () => '',
		},
		{
			heading: SECTION_TITLE,
			render: () => 'Section 2 - Details of Person(s) Involved',
		},
		{
			heading: SECTION_SEPARATOR,
			render: () => '',
		},
		{
			heading: 'Number of Person Involved',
			render: (prop) =>
				prop.incident_section_2.personInvolved.length.toString(),
		},
		{
			heading: 'Number of Witness(es)',
			render: (prop) => prop.incident_section_2.witnesses.length.toString(),
		}
	);

	incident.incident_section_2.personInvolved.forEach((person, index) => {
		mapper.push(
			{
				heading: `Name of Person Involved #${index + 1}`,
				render: () => person.personInvolved_name,
			},
			{
				heading: `Start Date of Person Involved #${index + 1}`,
				render: () =>
					formatDateTime(person?.personInvolved_start_date) || 'No Date',
			},
			{
				heading: `Tenure of Person Involved #${index + 1}`,
				render: () => formatTenure(person?.personInvolved_length_of_service),
			},
			{
				heading: `Phone Number of Person Involved #${index + 1}`,
				render: () => person.personInvolved_phone_number,
			}
		);
	});

	incident.incident_section_2.witnesses.forEach((witness, index) => {
		mapper.push(
			{
				heading: `Name of Witness ${index + 1}`,
				render: () => witness.witness_name,
			},
			{
				heading: `Position of Witness #${index + 1}`,
				render: () => witness.witness_position,
			},
			{
				heading: `Task of Witness #${index + 1}`,
				render: () => witness.witness_task,
			},
			{
				heading: `Phone Number of Witness #${index + 1}`,
				render: () => witness.witness_phone_number,
			}
		);
	});

	//  Section 3
	mapper.push(
		{
			heading: SECTION_END,
			render: () => '',
		},
		{
			heading: SECTION_TITLE,
			render: () => 'Section 3 - Injury/Incident Details',
		},
		{
			heading: 'Any Injuries',
			render: (prop) => (prop.incident_section_3.injuries ? 'Yes' : 'No'),
		}
	);

	if (incident.incident_section_3.injuries) {
		mapper.push(
			{
				heading: 'Part of Body Injured',
				render: (prop) =>
					prop.incident_section_3.body_injured
						.filter((injury) => injury.checked)
						.map((injury) => injury.title)
						.join(', '),
			},
			{
				heading: 'Injury Type',
				render: (prop) =>
					prop.incident_section_3.injury_type
						.filter((injury) => injury.checked)
						.map((injury) => injury.title)
						.join(', '),
			},
			{
				heading: 'Incident Mechanism',
				render: (prop) =>
					prop.incident_section_3.incident_mechanism
						.filter((injury) => injury.checked)
						.map((injury) => injury.title)
						.join(', '),
			},
			{
				heading: 'Incident Agency',
				render: (prop) =>
					prop.incident_section_3.incident_agency
						.filter((injury) => injury.checked)
						.map((injury) => injury.title)
						.join(', '),
			}
		);
	}

	//  Section 4
	mapper.push(
		{
			heading: SECTION_END,
			render: () => '',
		},
		{
			heading: SECTION_TITLE,
			render: () => 'Section 4 - Incident Analysis',
		},
		{
			heading: 'Severity Level',
			render: (prop) => prop.incident_section_4.severity_rating,
		},
		{
			heading: 'Incident Category',
			render: (prop) =>
				prop.incident_section_4.incident_category
					.filter((category) => category.checked)
					.map((category) => category.title)
					.join(', '),
		},
		{
			heading: SECTION_SEPARATOR,
			render: () => '',
		}
	);

	if (!incident.incident_section_3.injuries) {
		mapper.push(
			{
				heading: 'Incident Mechanism',
				render: (prop) =>
					prop.incident_section_4.incident_mechanism
						.filter((injury) => injury.checked)
						.map((injury) => injury.title)
						.join(', '),
			},
			{
				heading: 'Incident Agency',
				render: (prop) =>
					prop.incident_section_4.incident_agency
						.filter((injury) => injury.checked)
						.map((injury) => injury.title)
						.join(', '),
			},
			{
				heading: SECTION_SEPARATOR,
				render: () => '',
			}
		);
	}

	mapper.push({
		heading: 'Notifiable Incident',
		render: (prop) =>
			prop.incident_section_4.notifiable_incident ? 'Yes' : 'No',
	});

	if (incident.incident_section_4.notifiable_incident) {
		mapper.push(
			{
				heading: 'Reported to',
				render: (prop) => prop.incident_section_4.reported_to,
			},
			{
				heading: 'Reference Number',
				render: (prop) => prop.incident_section_4.reference_number,
			},
			{
				heading: 'Notes',
				render: (prop) => prop.incident_section_4.notes,
			}
		);
	}

	mapper.push(
		{
			heading: SECTION_SEPARATOR,
			render: () => '',
		},
		{
			heading: 'Breach of Life Saving Rules',
			render: (prop) =>
				prop.incident_section_4.life_saving_rule_breach ? 'Yes' : 'No',
		}
	);
	if (incident.incident_section_4.life_saving_rule_breach) {
		mapper.push({
			heading: 'Life Saving Rules',
			render: (prop) =>
				prop.incident_section_4.life_saving_rules
					.filter((rule) => rule.checked)
					.map((rule) => rule.title)
					.join(', '),
		});
	}
	mapper.push(
		{
			heading: SECTION_SEPARATOR,
			render: () => '',
		},
		{
			heading: 'Breach of Code of Conduct',
			render: (prop) =>
				prop.incident_section_4.code_of_conduct_breach ? 'Yes' : 'No',
		}
	);
	if (incident.incident_section_4.code_of_conduct_breach) {
		mapper.push({
			heading: 'Code of Conduct',
			render: (prop) =>
				prop.incident_section_4.code_of_conduct
					.filter((code) => code.checked)
					.map((code) => code.title)
					.join(', '),
		});
	}

	//  Section 5
	mapper.push(
		{
			heading: SECTION_END,
			render: () => '',
		},
		{
			heading: SECTION_TITLE,
			render: () => 'Section 5 - Incident Investigation',
		},
		{
			heading: 'Investigation Required',
			render: (prop) =>
				prop.incident_section_5.incident_investigation_required ? 'Yes' : 'No',
		}
	);
	if (incident.incident_section_5.incident_investigation_required) {
		mapper.push({
			heading: 'Investigator',
			render: (prop) =>
				`${prop.incident_section_5.investigator.first_name} ${prop.incident_section_5.investigator.last_name}`,
		});
	}

	mapper.push(
		{
			heading: SECTION_SEPARATOR,
			render: () => '',
		},
		{
			heading: 'Shift Start Time',
			render: (prop) => removeSeconds(prop.incident_section_5.shift_start_time),
		},
		{
			heading: 'Percentage of Shift Worked',
			render: (prop) => prop.incident_section_5.percentage_of_shift_worked,
		},
		{
			heading: SECTION_SEPARATOR,
			render: () => '',
		},
		{
			heading: 'Organisational Factors',
			render: (prop) => prop.incident_section_5.organisational_factors || '',
		},
		{
			heading: 'Organisational Factors 2',
			render: (prop) =>
				prop.incident_section_5.task_environmental_factors || '',
		},
		{
			heading: 'Individual / Team Factors',
			render: (prop) => prop.incident_section_5.individual_factors || '',
		},
		{
			heading: 'Absent / Failed Defenses',
			render: (prop) => prop.incident_section_5.absent_failed_defense || '',
		},
		{
			heading: SECTION_SEPARATOR,
			render: () => '',
		}
	);

	//  Section 7
	if (incident?.incident_section_7?.actions?.length) {
		mapper.push(
			{
				heading: SECTION_END,
				render: () => '',
			},
			{
				heading: SECTION_TITLE,
				render: () => 'Section 7 - Corrective Actions',
			}
		);

		incident?.incident_section_7?.actions?.forEach((action, index) => {
			mapper.push(
				{
					heading: `Action Description ${index + 1}`,
					render: () => action.action_description,
				},
				{
					heading: `Assigned To ${index + 1}`,
					render: () =>
						`${action.assignee?.first_name} ${action.assignee?.last_name}`,
				},
				{
					heading: `Completion Date ${index + 1}`,
					render: () =>
						new Date(action.completion_date)
							.toLocaleDateString('en-AU', {
								weekday: 'short',
								day: 'numeric',
								month: 'short',
							})
							.replace(',', ''),
				},
				{
					heading: `Hierachy of Control ${index + 1}`,
					render: () =>
						action.hierachy_of_control
							.filter((hoc) => hoc.is_selected)
							.map((hoc) => hoc.label)
							.join(', '),
				},
				{
					heading: `Action Taken ${index + 1}`,
					render: () => action.action_taken,
				},
				{
					heading: `Completed ${index + 1}`,
					render: () => (action.completed ? 'Yes' : 'No'),
				}
			);
		});
	}

	mapper.push({
		heading: SECTION_END,
		render: () => '',
	});

	const splitAtTitle = (arr: IPdfMapper): IPdfMapper[] => {
		const index = arr.findIndex((item) => item.heading === SECTION_END);

		if (index < arr.length - 1) {
			return [arr.slice(0, index + 1), ...splitAtTitle(arr.slice(index + 1))];
		}

		return [arr];
	};
	const splittedMapper = splitAtTitle(mapper);
	const primaryLogosState = logos.find((logo) => logo.is_primary);
	const companyLogosState = logos.filter((logo) => !logo.is_primary);

	return (
		<Document>
			<Page size="A4" style={styles.page} wrap>
				{primaryLogosState && (
					<View style={styles.headerContainer}>
						<View style={styles.topLogoContainer}>
							<Image
								src={primaryLogosState.logo.url}
								style={styles.logo}
								key={primaryLogosState.id}
							/>
						</View>
					</View>
				)}
				{companyLogosState.length > 0 && (
					<View style={styles.bottomLogoContainer}>
						{companyLogosState.map((logo) => (
							<Image
								src={logo.logo.url}
								style={styles.bottomLogo}
								key={logo.id}
							/>
						))}
					</View>
				)}
				<View style={styles.separator} />
				<Text style={styles.title}>Incident Report</Text>
				{splittedMapper.map((sectionMapper, index) => (
					<View break={index > 0} key={index}>
						{sectionMapper.map((item) => (
							<View style={styles.section}>
								{item.heading === SECTION_TITLE ? (
									<View style={styles.sectionTitle}>
										<Text style={styles.textKey}>{item.render(incident)}</Text>
									</View>
								) : item.heading === SECTION_SEPARATOR ? (
									<View style={styles.separatorContainer}>
										<View style={styles.sectionSeparator} />
									</View>
								) : item.heading === SECTION_END ? (
									<View style={styles.sectionEnd} />
								) : item.heading.length ? (
									<>
										<View style={[styles.container, styles.keyContainer]}>
											<Text style={styles.textKey}>{item.heading}</Text>
										</View>
										<View style={[styles.container, styles.valueContainer]}>
											<Text style={styles.textValue}>
												{item.render(incident)}
											</Text>
										</View>
									</>
								) : (
									<View style={styles.container} />
								)}
							</View>
						))}
					</View>
				))}
			</Page>
		</Document>
	);
};

export default PdfDocument;
