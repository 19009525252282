import { IMultiSelectComboBoxOption } from 'components/atoms/MultiSelectComboBox/MultiSelectComboBox';

import { IGetUser } from './User';

export type IQueryPeriod = 'today' | 'week' | 'month' | 'all';
export type IQueryReportSection =
	| 'section1'
	| 'section2'
	| 'section3'
	| 'section4'
	| 'section5'
	| 'section7';

export interface ITabState {
	section1: boolean;
	section2: boolean;
	section3: boolean;
	section4: boolean;
	section5: boolean;
}
export interface IVehicleIncident {
	id: number;
	uid: string;
	alloc8_id: string;
	type: 'incident';
	submitted_at: string;
	createdAt: string;
	updatedAt: string;
	status: 'new' | 'open' | 'closed' | 'draft';
	severity: 'normal' | 'high';
	special_status: 'duplicate' | 'nuisance' | null;
	ticket_number: string;
	description: string;
	submitted_by: string;
	completed: boolean;
	incident_section_1: {
		incident_date: Date;
		id: number;
	};
	incident_section_2: {
		id: number;
		personInvolved: {
			id: string;
			personInvolved_name: string;
		}[];
	};
	incident_section_3: {
		id: number;
		nuisance_report: boolean;
	};
	incident_section_4: {
		id: number;
		incident_category: { id: number; label: string }[];
		severity_rating?: string;
	};
	incident_section_5: {
		id: number;
	};
	incident_section_7: {
		id: number;
	};
	client: string;
	branch: {
		id: string;
		name: string;
	};
	incidents: {
		question: string;
		answer: string;
	}[];
	managers: {
		id: string;
		first_name: string;
		last_name: string;
	}[];
	assignees: {
		id: string;
		first_name: string;
		last_name: string;
	}[];
	incident_activities: {
		createdAt: string;
		name: string;
		type: string;
	};
}

export interface ISortIncidentData {
	branch: IOption[];
	client: IOption[];
	severity: IOption[];
	status: IOption[];
	state: IOption[];
	assignee: IOption[];
	involved: IOption[];
}

export interface IIncident {
	incidentData: {
		id: number;
		alloc8_id: string;
		submitted_at: string;
		createdAt: string;
		updatedAt: string;
		status: 'new' | 'open' | 'closed' | 'draft';
		severity: 'normal' | 'high';
		ticket_number: string;
		description: string;
		submitted_by: string;
		completed: boolean;
		client: string;
		incident_section_1: IVehicleIncidentSection1Payload;
		incident_section_2: IVehicleIncidentSection2;
		incident_section_3: IVehicleIncidentSection3Payload;
		incident_section_4: IVehicleIncidentSection4Payload;
		incident_section_5: IVehicleIncidentSection5Payload;
		incident_section_7: IVehicleIncidentSection7Payload;
	};
}

export interface ISections {
	incident_section_1: number | null;
	incident_section_2: number | null;
	incident_section_3: number | null;
	incident_section_4: number | null;
	incident_section_5: number | null;
}

export interface IVehicleCreateIncident {
	id: number;
	alloc8_id: null;
	client: null;
	description: null;
	severity: string;
	status: string;
	submitted_by: null;
	ticket_number: null;
	branch: number | null;
}

export interface IVehicleIncidentUpdate {
	id: number;
	branch: number | null;
	ticket_number: string;
	client_name: string;
	description: string;
}

export interface IVehicleIncidentSection1Payload {
	id: number | null;
	incidentId: number | null;
	createdAt?: string;
	incident_date: string;
	branch: string;
	reported_date: string;
	location: string;
	incident_time: string;
	reported_time: string;
	incident_description: string;
	client_name: string;
	job_details: string;
	mv_incident: boolean;
	altusRegistration: string;
	driverName: string;
	licenseClass: string;
	licenseExpiry: string;
	updatedAt?: string;
	branch_id?: string;
	thirdParty: boolean;
	thirdParties?: {
		id: number;
		thirdParty_name: string;
		thirdParty_vehicle_registration: string;
		thirdParty_licence_number: string;
		thirdParty_phone_number: string;
	}[];
	actionTaken: string;
	emergencyResponseAttendance: {
		id: number;
		title: string;
		checked: boolean;
	}[];
	emergencyResponseAttendanceOther: string;
	trafficControllersIntervened: boolean;
	workCeasedUnsafeConditions: boolean;
}

export interface IVehicleIncidentSection1 {
	description: string;
	location: string;
	client: string;
	jobDetails: string;
	altusRegistration: string;
	driverName: string;
	licenseClass: string;
	licenseExpiry: string;
	incident_date: Date;
	reported_date: Date;
	reported_time: string;
	branch: string;
	incident_time: string;
	mv_incident: boolean;
	thirdParty: boolean;
	thirdParties?: {
		id: number;
		thirdParty_name: string;
		thirdParty_vehicle_registration: string;
		thirdParty_licence_number: string;
		thirdParty_phone_number: string;
	}[];
	actionTaken: string;
	emergencyResponseAttendance: IOption[];
	emergencyResponseAttendanceOther: string;
	trafficControllersIntervened: boolean;
	workCeasedUnsafeConditions: boolean;
}

export interface IVehicleCreateIncidentSection1 {
	id: number;
	client_name: string;
	incident_date: string;
	incident_description: string;
	incident_time: string;
	job_details: string;
	location: string;
	mv_incident: boolean;
	branch: string;
	reported_date: string;
	altusRegistration: string;
	driverName: string;
	licenseClass: string;
	licenseExpiry: string;
	thirdParty: boolean;
	thirdParties?: {
		id: number;
		thirdParty_name: string;
		thirdParty_vehicle_registration: string;
		thirdParty_licence_number: string;
		thirdParty_phone_number: string;
	}[];
	actionTaken: string;
	emergencyResponseAttendance?: IOption[];
	emergencyResponseAttendanceOther?: string;
}

export interface IVehicleCreateIncidentSection3 {
	id: number;
	name: string;
	body_injured_other_description: null;
	incident_agency_other_description: null;
	incident_mechanism_other_description: null;
	injury_type_other_description: null;
	position: null;
	injuries: boolean;
	restricted_injury: boolean;
}

export interface IVehicleCreateIncidentSection3Payload {
	id: number;
	body_injured: {
		label: string;
		name: string;
		is_selected: boolean;
		description: string;
	}[];
	injury_type: {
		label: string;
		name: string;
		is_selected: boolean;
		description: string;
	}[];
	incident_mechanism: {
		label: string;
		name: string;
		is_selected: boolean;
		description: string;
	}[];
	incident_agency: {
		label: string;
		name: string;
		is_selected: boolean;
		description: string;
	}[];
}
export interface IVehicleCreateIncidentSection4 {
	id: number;
	name: string;
	reported_to: string;
	reference_number: string;
	notes: string;
	severity_rating: string;
	notifiable_incident: boolean;
	code_of_conduct_breach: boolean;
}

export interface IVehicleCreateIncidentSection4Payload {
	id: number;
	incident_category: {
		label: string;
		name: string;
		is_selected: boolean;
		description: string;
	}[];
	life_saving_rules: {
		label: string;
		name: string;
		is_selected: boolean;
		description: string;
	}[];
	code_of_conduct: {
		label: string;
		name: string;
		is_selected: boolean;
		description: string;
	}[];
}

export interface IVehicleCreateIncidentSection5 {
	id: number;
	name: string;
	absent_failed_defense: string;
	action_description: string;
	action_taken: string;
	individual_factors: string;
	organisational_factors: string;
	task_environmental_factors: string;
	percentage_of_shift_worked: string;
	portion_of_shift_worked: string;
	shift_start_time: string;
	completed: boolean;
	incident_investigation_required: boolean;
}

export interface IVehicleCreateIncidentSection5Payload {
	id: number;
}

export interface IVehicleCreateIncidentSection7 {
	id: number;
	name: string;
}

export interface IVehicleCreateIncidentSection7Payload {
	id: number;
}

export interface IVehicleIncidentSection2Payload {
	id: number;
	createdAt?: string;
	personInvolved: {
		id: number;
		personInvolved_name: string;
		personInvolved_phone_number: string;
		personInvolved_start_date: string;
	}[];
	witness_exists: boolean;
	witnesses: {
		id: number;
		witness_name: string;
		witness_position: string;
		witness_task: string;
		witness_phone_number: string;
	}[];
}

export interface IVehicleIncidentSection2 {
	id: number | null;
	personInvolved: {
		id: number;
		personInvolved_name: string;
		personInvolved_start_date: Date;
		personInvolved_phone_number: string;
		personInvolved_length_of_service?: string;
	}[];
	witness_exists: boolean;
	witnesses: {
		id: number;
		witness_name: string;
		witness_position: string;
		witness_task: string;
		witness_phone_number: string;
	}[];
}

export interface IVehicleIncidentSection3 {
	bodyParts: IOption[];
	bodyPartsInjured: IOption[];
	bodyPartsInjuredOtherInput: string;
	injuryType: IOption[];
	injuryTypeOtherInput: string;
	incidentMechanism: IOption[];
	incidentMechanismOtherInput: string;
	incidentAgency: IOption[];
	mviCausation: IOption[];
	incidentAgencyOtherInput: string;
	injuries: boolean;
	restricted_injury: boolean;
}

export interface IVehicleIncidentSection3Payload {
	id: number;
	injury_type_other_description: string;
	incident_mechanism_other_description: string;
	incident_agency_other_description: string;
	body_injured_other_description: string;
	injuries: boolean;
	restricted_injury: boolean;
	body_injured: {
		id: number;
		title: string;
		checked: boolean;
	}[];
	injury_type: {
		id: number;
		title: string;
		checked: boolean;
	}[];
	incident_mechanism: {
		id: number;
		title: string;
		checked: boolean;
	}[];
	incident_agency: {
		id: number;
		title: string;
		checked: boolean;
	}[];
	mvi_causation: {
		id: number;
		title: string;
		checked: boolean;
	}[];
}

export interface IVehicleIncidentSection4 {
	severityRating: string;
	reportedTo: string;
	referenceNumber: string;
	notes: string;
	isNotifiableIncident: boolean;
	isLifeSavingBreach: boolean;
	isCodeOfConductBreach: boolean;
	incidentCategory: {
		id: number;
		title: string;
		checked: boolean;
		description: string;
	}[];
	incidentMechanism: IOption[];
	incidentMechanismOtherInput: string;
	incidentAgency: IOption[];
	incidentAgencyOtherInput: string;
	lifeSavingRules: {
		id: number;
		title: string;
		checked: boolean;
		description: string;
	}[];
	mviCausation: {
		id: number;
		title: string;
		checked: boolean;
		description?: string;
	}[];
	codeOfConduct: {
		id: number;
		title: string;
		checked: boolean;
	}[];
}
export interface IVehicleIncidentSection4Payload {
	id: number;
	severity_rating: string;
	reported_to: string;
	reference_number: string;
	notes: string;
	coaching_evidence_file?: {
		id: number;
		name: string;
	} | null;
	notifiable_incident: boolean;
	life_saving_rule_breach: boolean;
	code_of_conduct_breach: boolean;
	incident_category: {
		id: number;
		title: string;
		checked: boolean;
		description: string;
	}[];
	incident_mechanism: {
		id: number;
		title: string;
		checked: boolean;
	}[];
	incident_agency: {
		id: number;
		title: string;
		checked: boolean;
	}[];
	mvi_causation: {
		id: number;
		title: string;
		checked: boolean;
	}[];
	incident_mechanism_other_description: string;
	incident_agency_other_description: string;
	life_saving_rules: {
		id: number;
		title: string;
		checked: boolean;
		description: string;
	}[];
	code_of_conduct: {
		id: number;
		title: string;
		checked: boolean;
	}[];
}

export interface IVehicleIncidentSection5 {
	id: number;
	name?: string;
	createdAt?: string;
	updatedAt?: string;
	shift_start_time: string;
	incident_investigation_required: boolean;
	organisational_factors?: string;
	task_environmental_factors?: string;
	individual_factors?: string;
	absent_failed_defense?: string;
	percentage_of_shift_worked: string;
	isReportFinished?: boolean;
	investigator: string;
	users: IMultiSelectComboBoxOption[] | { id: string; label: string }[];
	section1Id: number | null;
	incidentReportId: number | null;
	attachments: File[];
}

export interface IVehicleIncidentSection5Payload {
	id: number | null;
	name?: string;
	createdAt?: string;
	updatedAt?: string;
	shift_start_time: string;
	incident_investigation_required: boolean;
	organisational_factors?: string;
	task_environmental_factors?: string;
	individual_factors?: string;
	absent_failed_defense?: string;
	percentage_of_shift_worked: string;
	isReportFinished?: boolean;
	assignees: IGetUser[];
	investigator: {
		first_name: string;
		last_name: string;
	};
	users: IMultiSelectComboBoxOption[] | { id: string; label: string }[];
	section1Id: number | null;
	incidentReportId: number | null;
}

export interface IVehicleIncidentSection7 {
	id: number | null;
	users: IMultiSelectComboBoxOption[] | { id: string; label: string }[];
	incidentReportId: number | null;
	section1Id: number | null;
	actions: {
		id: number;
		action_description: string;
		hierachy_of_control: {
			id?: number;
			label: string;
			name: string;
			is_selected: boolean;
		}[];
		assignee?:
			| number
			| {
					id: number;
					first_name: string;
					last_name: string;
			  };
		action_taken: string;
		completion_date: Date;
		completed: boolean;
	}[];
}

export interface IVehicleIncidentSection7Payload {
	id: number | null;
	users: IMultiSelectComboBoxOption[] | { id: string; label: string }[];
	incidentReportId: number | null;
	section1Id: number | null;
	actions: {
		id: number;
		action_description: string;
		hierachy_of_control: {
			id?: number;
			label: string;
			name: string;
			is_selected: boolean;
		}[];
		assignee?: IGetUser;
		action_taken: string;
		completion_date: Date;
		completed: boolean;
	}[];
}

export interface ISection1ErrorStates {
	section_1_incident_description: boolean;
	section_1_location: boolean;
	section_1_client_name: boolean;
	section_1_job_details: boolean;
	section_1_incident_date: boolean;
	section_1_reported_date: boolean;
	section_1_incident_time: boolean;
	section_1_reported_time: boolean;
}

export interface ISection2ErrorStates {}

export interface ISection3ErrorStates {
	section_3_body_parts: boolean;
	section_3_injury_type: boolean;
	section_3_incident_mechanism: boolean;
	section_3_incident_agency: boolean;
}

export interface ISection4ErrorStates {
	section_4_incident_category: boolean;
	section_4_reported_to: boolean;
	section_4_reference_number: boolean;
	section_4_life_saving_rules: boolean;
	section_4_code_of_conduct_checkbox: boolean;
	section_4_incident_mechanism: boolean;
	section_4_incident_agency: boolean;
}

export interface ISection5ErrorStates {
	section_5_shift_start_time: boolean;
	section_5_investigator: boolean;
}

export interface ISection7ErrorStates {
	section_7_hierachy_control: boolean;
	section_7_action_input: boolean;
	section_7_assigned_to: boolean;
}

export type ISection1Type =
	| 'description'
	| 'location'
	| 'client'
	| 'incident_time'
	| 'jobDetails'
	| 'altusRegistration'
	| 'driverName'
	| 'licenseClass'
	| 'incident_date'
	| 'reported_date'
	| 'licenseExpiry'
	| 'branch'
	| 'thirdParty_name'
	| 'thirdParty_vehicle_registration'
	| 'thirdParty_licence_number'
	| 'thirdParty_phone_number'
	| 'actionTaken'
	| 'reported_time'
	| 'emergencyResponseAttendance'
	| 'emergencyResponseAttendanceOther';

export type ISection2Type =
	| 'personInvolved_name'
	| 'personInvolved_start_date'
	| 'personInvolved_phone_number'
	| 'witness_name'
	| 'witness_phone_number'
	| 'witness_position'
	| 'witness_task';

export type ISection5Type =
	| 'percentageShiftWorked'
	| 'shiftStartTime'
	| 'organisationalFactorsInput'
	| 'organisationalFactors2Input'
	| 'individualFactorsInput'
	| 'absentDefenseInput'
	| 'investigator'
	| 'users'
	| 'attachments'
	| 'actions';

export interface IFilters {
	[name: string]: IOption[] | string | null;
}
export interface IOption {
	id: number;
	title: string;
	checked: boolean;
}

const branch = [{ id: 1, title: '', checked: true }];

const severity = [
	{ id: 1, title: 'Level 1', checked: true },
	{ id: 2, title: 'Level 2', checked: true },
	{ id: 3, title: 'Level 3', checked: true },
	{ id: 4, title: 'Level 4', checked: true },
	{ id: 5, title: 'Level 5', checked: true },
];

const status = [
	{ id: 1, title: 'Open', checked: true },
	{ id: 2, title: 'Close', checked: true },
	{ id: 3, title: 'Draft', checked: true },
];

const incidentCategory = [
	{
		id: 1,
		title: 'Lost Time Injury (LTI)',
		checked: true,
	},
	{
		id: 2,
		title: 'Medical Treatment Injury (MTI)',
		checked: true,
	},
	{
		id: 3,
		title: 'First Aid Injury (FAI)',
		checked: true,
	},
	{
		id: 4,
		title: 'No Treatment Injury (NOT)',
		checked: true,
	},
	{
		id: 5,
		title: 'Non-Compliance (NC)',
		checked: true,
	},
	{
		id: 6,
		title: 'Plant & Equipment Incident (P&E)',
		checked: true,
	},
	{
		id: 7,
		title: 'Motor Vehicle Incident (MVI)',
		checked: true,
	},
	{
		id: 8,
		title: 'Motor Vehicle Incident (MVI3)',
		checked: true,
	},
	{
		id: 9,
		title: 'Motor Vehicle Incident (MVI4)',
		checked: true,
	},
	{
		id: 10,
		title: 'Property Incident (PI)',
		checked: true,
	},
	{
		id: 11,
		title: 'Environment Incident (ENV)',
		checked: true,
	},
	{
		id: 12,
		title: 'Near Miss Incident (NMI)',
		checked: true,
	},
	{
		id: 13,
		title: 'Client Complaint (CC)',
		checked: true,
	},
	{
		id: 14,
		title: 'Public Complaint (PC)',
		checked: true,
	},
	{
		id: 15,
		title: 'Internal Complaint (IC)',
		checked: true,
	},
	{
		id: 16,
		title: 'Third Party behaviour (TPB)',
		checked: true,
	},
	{
		id: 17,
		title: 'Other (OTH)',
		checked: true,
	},
];

const lifeSavingRules = [
	{ id: 1, title: 'Drug and Alcohol Free Workplace', checked: true },
	{ id: 2, title: 'Safe Driving', checked: true },
	{ id: 3, title: 'Exclusion Zones', checked: true },
	{ id: 4, title: 'Mobile Phones', checked: true },
	{ id: 5, title: 'Working In and Around Live Traffic', checked: true },
	{ id: 6, title: 'Safety Controls', checked: true },
];

const codeOfConduct = [
	{ id: 1, title: 'Personal Conduct', checked: true },
	{ id: 2, title: 'Respect for Others', checked: true },
	{ id: 3, title: 'Community and Environment', checked: true },
	{ id: 4, title: 'Standard of Dress & Appearance', checked: true },
	{ id: 5, title: 'Attendance and Availability', checked: true },
	{ id: 6, title: 'Conflict of Interest', checked: true },
];

const restrictedInjury = [
	{ id: 1, title: 'Yes', checked: true },
	{ id: 2, title: 'No', checked: true },
];

export const initialFilterState: IFilters = {
	branch,
	severity,
	status,
	to: null,
	from: null,
	codeOfConduct,
	lifeSavingRules,
	incidentCategory,
	restrictedInjury,
};

export interface IOption {
	id: number;
	title: string;
	checked: boolean;
	description?: string;
}

export const section4RadioButtons = [
	{
		id: '1',
		title: 'Level 1',
	},
	{
		id: '2',
		title: 'Level 2',
	},
	{
		id: '3',
		title: 'Level 3',
	},
	{
		id: '4',
		title: 'Level 4',
	},
	{
		id: '5',
		title: 'Level 5',
	},
];

export const section5PercentageRadioButtons = [
	{
		id: '1',
		title: 'N/A',
	},
	{
		id: '2',
		title: '0% - 10%',
	},
	{
		id: '3',
		title: '11% - 50%',
	},
	{
		id: '4',
		title: '51% - 80%',
	},
	{
		id: '5',
		title: '81% - 100%',
	},
	{
		id: '6',
		title: '100% +',
	},
];

export const section5PortionRadioButtons = [
	{
		id: '1',
		title: 'Immediate',
	},
	{
		id: '2',
		title: 'Medium',
	},
	{
		id: '3',
		title: 'Long',
	},
];

//Keeping all this data for now to transfer to JSON when we need to create reports in future ticket
export const bodyParts = [
	{ id: 1, title: 'Left', checked: false },
	{ id: 2, title: 'First Right', checked: false },
	{ id: 3, title: 'Upper', checked: false },
	{ id: 4, title: 'Lower', checked: false },
];

export const bodyPartsInjured = [
	{ id: 5, title: 'Finger', checked: false },
	{ id: 6, title: 'Arm', checked: false },
	{ id: 7, title: 'Neck', checked: false },
	{ id: 8, title: 'Nose', checked: false },
	{ id: 9, title: 'Jaw', checked: false },
	{ id: 10, title: 'Hip', checked: false },
	{ id: 11, title: 'Foot', checked: false },
	{ id: 12, title: 'Hand', checked: false },
	{ id: 13, title: 'Elbow', checked: false },
	{ id: 14, title: 'Forearm', checked: false },
	{ id: 15, title: 'Head', checked: false },
	{ id: 16, title: 'Ear', checked: false },
	{ id: 17, title: 'Collar', checked: false },
	{ id: 18, title: 'Rib', checked: false },
	{ id: 19, title: 'Mouth/Teeth', checked: false },
	{ id: 20, title: 'Leg', checked: false },
	{ id: 21, title: 'Knee', checked: false },
	{ id: 22, title: 'Shin', checked: false },
	{ id: 23, title: 'Calf', checked: false },
	{ id: 24, title: 'Toe', checked: false },
	{ id: 25, title: 'Wirst', checked: false },
	{ id: 26, title: 'Shoulder', checked: false },
	{ id: 27, title: 'Eye', checked: false },
	{ id: 28, title: 'Back', checked: false },
	{ id: 29, title: 'Psych', checked: false },
	{ id: 30, title: 'Ankle', checked: false },
	{
		id: 31,
		title: 'Internal Organs',
		checked: false,
	},
	{ id: 32, title: 'Other', checked: false },
];

export const injuryType = [
	{
		id: 1,
		title: 'Sprain/Strain',
		checked: false,
	},
	{
		id: 2,
		title: 'Foreign Body',
		checked: false,
	},
	{ id: 3, title: 'Dislocation', checked: false },
	{
		id: 4,
		title: 'Heat Illness',
		checked: false,
	},
	{
		id: 5,
		title: 'Electric Shock',
		checked: false,
	},
	{ id: 6, title: 'Crush', checked: false },
	{
		id: 7,
		title: 'Bruise/Contusion',
		checked: false,
	},
	{
		id: 8,
		title: 'Fracture/Break',
		checked: false,
	},
	{
		id: 9,
		title: 'Consciousness',
		checked: false,
	},
	{
		id: 10,
		title: 'Exposure Substance',
		checked: false,
	},
	{
		id: 11,
		title: 'Cut/Laceration',
		checked: false,
	},
	{ id: 12, title: 'Burn', checked: false },
	{
		id: 13,
		title: 'Inflammation',
		checked: false,
	},
	{ id: 14, title: 'Respiratory', checked: false },
	{ id: 15, title: 'Dermatitis', checked: false },
	{
		id: 16,
		title: 'Allergy/Reaction',
		checked: false,
	},
	{ id: 17, title: 'Stress', checked: false },
	{ id: 18, title: 'Death', checked: false },
	{ id: 19, title: 'Other', checked: false },
];

export const incidentMechanism = [
	{
		id: 1,
		title: 'Exposure to Heat',
		checked: false,
	},
	{
		id: 2,
		title: 'Exposure to Cold',
		checked: false,
	},
	{
		id: 3,
		title: 'Fall - Same Level',
		checked: false,
	},
	{
		id: 4,
		title: 'Fall - Different Level incl. Height',
		checked: false,
	},
	{
		id: 5,
		title: 'Struck By',
		checked: false,
	},
	{
		id: 6,
		title: 'Struck Against',
		checked: false,
	},
	{
		id: 7,
		title: 'Contact With',
		checked: false,
	},
	{
		id: 8,
		title: 'Exposure Substance',
		checked: false,
	},
	{
		id: 9,
		title: 'Caught Between',
		checked: false,
	},
	{
		id: 10,
		title: 'Slip / Trip',
		checked: false,
	},
	{
		id: 11,
		title: 'Absorption/Ingestion or Inhalation',
		checked: false,
	},
	{
		id: 12,
		title: 'Using Tools/Equipment',
		checked: false,
	},
	{
		id: 13,
		title: 'Repetitive Motion',
		checked: false,
	},
	{
		id: 14,
		title: 'Falling/Moving Object',
		checked: false,
	},
	{
		id: 15,
		title: 'Bitten',
		checked: false,
	},
	{
		id: 16,
		title: 'Fitness for Work',
		checked: false,
	},
	{
		id: 17,
		title: 'Verbal Confrontation',
		checked: false,
	},
	{
		id: 18,
		title: 'Physical Altercation',
		checked: false,
	},
	{
		id: 19,
		title: 'Reversing Vehicle',
		checked: false,
	},
	{
		id: 20,
		title: 'Body Motion or Posture',
		checked: false,
	},
	{
		id: 21,
		title: 'Accessing/Egressing',
		checked: false,
	},
	{
		id: 22,
		title: 'Other',
		checked: false,
	},
];

export const incidentAgency = [
	{
		id: 1,
		title: 'Stop/Slow Bat',
		checked: false,
	},
	{
		id: 2,
		title: 'Motor Vehicle - Ute',
		checked: false,
	},
	{
		id: 3,
		title: 'Motor Vehicle - Car',
		checked: false,
	},
	{
		id: 4,
		title: 'Motor Vehicle - Truck',
		checked: false,
	},
	{
		id: 5,
		title: 'Trailer other Vehicle Attachment',
		checked: false,
	},
	{
		id: 6,
		title: 'Boxes/Crates/Cartons etc',
		checked: false,
	},
	{ id: 7, title: 'Needles', checked: false },
	{
		id: 8,
		title: 'Loading/Unloading',
		checked: false,
	},
	{
		id: 9,
		title: 'Forklift Trucks',
		checked: false,
	},
	{
		id: 10,
		title: 'Containers/Cylinders etc',
		checked: false,
	},
	{ id: 11, title: 'Ladders', checked: false },
	{ id: 12, title: 'Electricity', checked: false },
	{
		id: 13,
		title: 'Lifting Devices',
		checked: false,
	},
	{
		id: 14,
		title: 'Tools & Equipment',
		checked: false,
	},
	{
		id: 15,
		title: 'Temperature Extremes',
		checked: false,
	},
	{
		id: 16,
		title: 'Mobile Plant',
		checked: false,
	},
	{
		id: 17,
		title: 'Bags/Sacks/Drums etc',
		checked: false,
	},
	{
		id: 18,
		title: 'Signs & Signage Equipment',
		checked: false,
	},
	{
		id: 19,
		title: 'Bunting/Taper & Witches Hats',
		checked: false,
	},
	{ id: 20, title: 'Dust/Debris', checked: false },
	{ id: 21, title: 'Noise', checked: false },
	{
		id: 22,
		title: 'Pressurized Liquid',
		checked: false,
	},
	{
		id: 23,
		title: 'Wet/Icy Surfaces',
		checked: false,
	},
	{
		id: 24,
		title: 'Uneven/Surfaces',
		checked: false,
	},
	{
		id: 25,
		title: 'Unprotect edges',
		checked: false,
	},
	{
		id: 26,
		title: 'Electrical Current',
		checked: false,
	},
	{ id: 27, strapi_label: 'radiation', title: 'Radiation/UV', checked: false },
	{
		id: 28,
		title: 'Chemicals & Substances',
		checked: false,
	},
	{
		id: 29,
		title: 'Doors/Windows',
		checked: false,
	},
	{
		id: 30,
		title: 'Walkways & Floors',
		checked: false,
	},
	{ id: 31, strapi_label: 'ergonomic', title: 'Ergonomic', checked: false },
	{
		id: 32,
		title: 'Animal or Insect',
		checked: false,
	},
	{ id: 33, title: 'Other Human', checked: false },
	{ id: 34, title: 'Asbestos/Lead', checked: false },
	{ id: 35, title: 'Steps or Stairs', checked: false },
	{
		id: 36,
		title: 'Body Motion or Posture',
		checked: false,
	},
	{ id: 37, title: 'Drugs', checked: false },
	{ id: 38, title: 'Alcohol', checked: false },
	{ id: 39, title: 'Other', checked: false },
];

export interface IIncidentCategorySummary {
	id: number;
	name: string;
	statistics: {
		closed: number;
		draft: number;
		open: number;
		submitted: number;
		total: number;
	};
}

export interface IGraphCategoryBranch {
	name: string;
	total: number;
	open: number;
	closed: number;
}

export interface IGraphCategory {
	name: string;
	total: number;
	open: number;
	closed: number;
	branches: IGraphCategoryBranch[];
}

export interface IIncidentCategoryBreakdown {
	categories: IIncidentCategorySummary[];
	graphCategories: IGraphCategory[];
	total: number;
}

export interface IIncidentCategoryBreakdownFilters {
	branchId?: number;
	dateFrom?: string;
	dateTo?: string;
}

export const emergencyResponseOptions = [
	{
		id: 1,
		title: 'Police',
		checked: false,
	},
	{
		id: 2,
		title: 'Fire Department',
		checked: false,
	},
	{
		id: 3,
		title: 'Ambulance',
		checked: false,
	},
	{
		id: 4,
		title: 'Other',
		checked: false,
	},
];
