import CheckBox from 'components/atoms/CheckBox';
import Input from 'components/atoms/Input';
import Radio from 'components/atoms/Radio';
import Text from 'components/atoms/Text';
import ToggleButton from 'components/atoms/ToggleButton';
import React, { useEffect, useState } from 'react';
import { usePrevious } from 'redux/hooks';
import { SurveyQuestionTypes } from 'types/Surveys';

export interface ISurveySubmissionQuestion {
	num: number;
	question: string;
	type: SurveyQuestionTypes;
	options: string[];
	onChange: (value: string) => void;
}

const SurveySubmissionQuestion: React.FC<ISurveySubmissionQuestion> = ({
	num,
	question,
	type,
	options,
	onChange,
}) => {
	const [toggleValue, setToggleValue] = useState(false);
	const [value, setValue] = useState('');
	const [checkBoxesValue, setCheckBoxesValue] = useState(
		Array(options.length).fill(false)
	);
	const previous = usePrevious({ toggleValue });

	useEffect(() => {
		if (
			type === 'questions.true-false' &&
			previous &&
			previous?.toggleValue !== toggleValue
		) {
			onChange(toggleValue ? 'true' : 'false');
		}
	}, [onChange, toggleValue, type, previous]);

	const handleChangeCheckBoxValue = (value: boolean, index: number) => {
		const newCheckBoxesValue = [...checkBoxesValue];
		newCheckBoxesValue[index] = value;
		setCheckBoxesValue(newCheckBoxesValue);

		const newValue = newCheckBoxesValue
			.reduce(
				(out, bool, index) => (bool ? out.concat(options[index]) : out),
				[]
			)
			.join(',');
		setValue(newValue);
		onChange(newValue);
	};

	const handleChangeTextValue = (value: string) => {
		setValue(value);
		onChange(value);
	};

	const getElement = (type: SurveyQuestionTypes, options: string[]) => {
		switch (type) {
			case 'questions.multiple-choice':
				return options.map((option, index) => (
					<CheckBox
						key={option}
						item={{ checked: checkBoxesValue[index], title: option, id: index }}
						onChange={(e) => handleChangeCheckBoxValue(e.target.checked, index)}
					/>
				));
			case 'questions.multi-choice':
				return (
					<Radio
						className="mt-5 mb-7"
						options={options.map((option) => {
							return { title: option, id: option };
						})}
						onChange={handleChangeTextValue}
						defaultId={options[0]}
					/>
				);
			case 'questions.true-false':
				return (
					<ToggleButton
						containerClassName="my-2"
						toggle={toggleValue}
						setToggle={setToggleValue}
					/>
				);
			case 'questions.free-text':
			default:
				return (
					<Input type="text" value={value} onChange={handleChangeTextValue} />
				);
		}
	};

	return (
		<div className="flex flex-col w-full my-2">
			<Text className="text-lg" type="bold">{`${num}. ${question}`}</Text>
			{getElement(type, options)}
		</div>
	);
};

export default SurveySubmissionQuestion;
