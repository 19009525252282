import React, { useState, MouseEventHandler, forwardRef, useRef } from 'react';
import DatePicker from 'react-datepicker';
import { CalendarIcon } from '@heroicons/react/outline';
import 'react-datepicker/dist/react-datepicker.css';
import './DateInput.css';
import DateInputChildInput from '../DateInputChildInput';
import { useAppSelector } from 'redux/hooks';
import { updateFilterState } from 'redux/slices/vehicleFaultSlice';
import { useDispatch } from 'react-redux';
import { updateIncidentFilterState } from 'redux/slices/vehicleIncidentsSlice';
import { updateSurveySearchFilterState } from 'redux/slices/surveys';

export interface IDateInput {
	label?: string;
	required?: boolean;
	placeholder?: string;
	selected?: Date | null;
	className?: string;
	onChange?: (item: Date) => void;
	inputLabel?: string;
	iconClassName?: string;
	wrapperClassName?: 'react-datepicker-margin-y' | 'react-datepicker-margin-0';
	filterDateType?: 'incident' | 'fault' | 'survey' | 'none';
	errorMessage?: string;
	isDisabled?: boolean;
	onBlur?: () => void;
}

interface IDateInputProps {
	onClick: MouseEventHandler<HTMLDivElement> | undefined;
}

const DateInput: React.FC<IDateInput> = ({
	selected,
	onChange,
	inputLabel = '',
	className,
	label,
	required = false,
	placeholder,
	iconClassName = 'text-black',
	wrapperClassName = 'react-datepicker-margin-y',
	filterDateType = 'none',
	errorMessage,
	isDisabled = false,
	onBlur,
}) => {
	const dispatch = useDispatch();
	const filters = useAppSelector((state) => state.vehicleFault.filters);
	const incidentFilters = useAppSelector(
		(state) => state.vehicleIncident.filters
	);
	const surveyFilters = useAppSelector((state) => state.surveys.searchFilter);
	const [date, setDate] = useState(selected);
	const inputRef = useRef<HTMLInputElement>(null);

	const handleOnChange = (newDate: Date) => {
		if (filterDateType === 'none') {
			onChange?.(newDate);
			setDate(newDate);
			return;
		}

		if (filterDateType === 'fault') {
			const filterState = JSON.parse(JSON.stringify(filters));
			filterState[inputLabel] = newDate.toISOString();
			setDate(newDate);
			dispatch(updateFilterState(filterState));
		} else if (filterDateType === 'incident') {
			const filterState = JSON.parse(JSON.stringify(incidentFilters));
			filterState[inputLabel] = newDate.toISOString();
			setDate(newDate);
			dispatch(updateIncidentFilterState(filterState));
		} else if (filterDateType === 'survey') {
			const filterState = JSON.parse(JSON.stringify(surveyFilters));
			filterState[inputLabel] = newDate.toISOString();
			setDate(newDate);
			dispatch(updateSurveySearchFilterState(filterState));
		}
	};

	const handleInputOnChange = (
		newDate: React.ChangeEvent<HTMLInputElement>
	) => {
		const newRaw = new Date(newDate.target.value);
		// @ts-ignore
		if (newRaw instanceof Date && !isNaN(newRaw)) {
			setDate(newRaw);
		}
	};

	const DateInput = forwardRef(
		({ onClick, ...props }: IDateInputProps, ref) => {
			return (
				<div className={`${className}`} aria-hidden="true">
					<DateInputChildInput
						type="text"
						iconPosition="trailing"
						Icon={CalendarIcon}
						isDisabled={isDisabled}
						className={`date-input z-40 font-inter font-normal tracking-normal m-auto`}
						size="normal"
						label={label}
						required={required}
						placeholder={placeholder}
						iconClassName={iconClassName}
						errorMessage={errorMessage}
						onClick={isDisabled ? undefined : onClick}
						{...props}
						ref={ref}
					/>
				</div>
			);
		}
	);

	return (
		<DatePicker
			selected={date}
			dateFormat={['dd/MM/yyyy', 'dd-MM-yyyy']}
			onChangeRaw={handleInputOnChange}
			onChange={handleOnChange}
			onSelect={onBlur}
			customInput={<DateInput onClick={() => null} ref={inputRef} />}
			className="rounded-xl"
			wrapperClassName={`${wrapperClassName}`}
			strictParsing={true}
		/>
	);
};

export default DateInput;
