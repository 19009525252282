import { IPdfCompanyLogo } from 'types/PdfCompanyLogo';
import { baseAPI } from './baseAPI';

export const pdfCompanyLogoAPI = baseAPI.injectEndpoints({
	endpoints: (builder) => ({
		getList: builder.query<IPdfCompanyLogo[], void>({
			query() {
				return {
					url: '/pdf-company-logo/list',
					method: 'GET',
				};
			},
		}),
	}),
});

export const { useGetListQuery } = pdfCompanyLogoAPI;
