import Heading from 'components/atoms/Heading';
import React from 'react';

export interface ISurveySubmissionNotification {
	notification: string;
}

const SurveySubmissionNotification: React.FC<ISurveySubmissionNotification> = ({
	notification,
}) => {
	return (
		<div className="flex grow flex-col justify-center h-screen">
			<div className="flex justify-center">
				<Heading type="h1">{notification}</Heading>
			</div>
		</div>
	);
};

export default SurveySubmissionNotification;
