//formats to YYYY-MM-DD
export const formatDate = (value: string | Date | null) => {
	if (!value) {
		return;
	}

	const date = new Date(value);
	const formattedDate = [
		date.getFullYear(),
		`0${date.getMonth() + 1}`.slice(-2),
		`0${date.getDate()}`.slice(-2),
	].join('-');
	return formattedDate;
};
