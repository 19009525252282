import {
	IIncident,
	ISortIncidentData,
	IVehicleCreateIncident,
	IVehicleCreateIncidentSection1,
	IVehicleCreateIncidentSection3,
	IVehicleCreateIncidentSection3Payload,
	IVehicleCreateIncidentSection4,
	IVehicleCreateIncidentSection4Payload,
	IVehicleCreateIncidentSection5,
	IVehicleCreateIncidentSection5Payload,
	IVehicleIncident,
	IVehicleIncidentSection1Payload,
	IVehicleIncidentSection2,
	IVehicleIncidentSection2Payload,
	IVehicleIncidentSection3Payload,
	IVehicleIncidentSection4Payload,
	IVehicleIncidentSection5,
	IVehicleIncidentUpdate,
	IIncidentCategoryBreakdown,
	IIncidentCategoryBreakdownFilters,
	IVehicleCreateIncidentSection7Payload,
	IVehicleCreateIncidentSection7,
	IVehicleIncidentSection7,
} from 'types/VehicleIncidents';

import { baseAPI } from './baseAPI';

interface IAssignPayload {
	id: number;
	body: {
		status: 'new' | 'open' | 'closed' | 'draft';
		assignee: string | undefined;
	};
}

interface ISpecialStatusPayload {
	id: number;
	body: {
		special_status: 'duplicate' | 'nuisance' | null;
	};
}

export interface IVehicleIncidentActivity {
	content: string;
	createdAt: Date;
	id: number;
	type: 'comment' | 'status' | 'assign';
	user?: {
		id: string;
		first_name: string;
		last_name: string;
		avatar?: {
			url: string;
		};
	};
}

export interface IVehicleIncidentCommentPayload {
	incident?: number;
	type: 'comment' | 'view report' | 'export';
	content: string;
	user?: number;
}

export const inventoryAPI = baseAPI.injectEndpoints({
	endpoints: (builder) => ({
		uploadFile: builder.mutation<File[], FormData>({
			query: (formData) => ({
				url: '/upload',
				method: 'POST',
				body: formData,
			}),
		}),
		getBranches: builder.query<{ id: string; label: string }[], void>({
			query() {
				return {
					url: '/branches',
					method: 'GET',
				};
			},
		}),
		getVehicleIncidents: builder.mutation<
			{
				sortData: ISortIncidentData[];
				data: IVehicleIncident[];
			},
			string
		>({
			query(period) {
				return {
					url: `/incidents?${period}`,
					method: 'GET',
				};
			},
		}),
		getAllIncidentSections: builder.query<any, { id: number }>({
			query(payload) {
				return {
					url: `/incidents/all-sections/${payload.id}`,
					method: 'GET',
				};
			},
		}),
		createIncident: builder.mutation<
			IVehicleCreateIncident,
			{ branch: number | null }
		>({
			query(payload) {
				return {
					url: `/incidents`,
					method: 'POST',
					body: { data: payload },
				};
			},
		}),
		updateIncident: builder.mutation<void, IVehicleIncidentUpdate>({
			query(payload) {
				return {
					url: `/incidents/${payload.id}`,
					method: 'PUT',
					body: { data: payload },
				};
			},
		}),
		getIncident: builder.mutation<IIncident, number>({
			query(payload) {
				return {
					url: `/incidents/${payload}`,
					method: 'GET',
				};
			},
		}),
		assignIncident: builder.mutation<
			void,
			IAssignPayload | ISpecialStatusPayload
		>({
			query(payload) {
				return {
					url: `/incident/status/${payload.id}`,
					method: 'PUT',
					body: payload.body,
				};
			},
		}),
		getIncidentActivity: builder.mutation<IVehicleIncidentActivity[], number>({
			query(incidentId) {
				return {
					url: `/incidents/${incidentId}/activity`,
					method: 'GET',
				};
			},
		}),
		CreateIncidentActivity: builder.mutation<
			void,
			IVehicleIncidentCommentPayload
		>({
			query(payload) {
				return {
					url: `/incident-activities`,
					method: 'POST',
					body: { data: payload },
				};
			},
		}),
		getIncidentSection1: builder.mutation<
			{ data: IVehicleIncidentSection1Payload },
			number
		>({
			query(incident_id) {
				return {
					url: `/incident-section-1s/${incident_id}`,
					method: 'GET',
				};
			},
		}),
		createIncidentSection1: builder.mutation<
			IVehicleCreateIncidentSection1,
			IVehicleIncidentSection1Payload
		>({
			query(payload) {
				return {
					url: `/incident-section-1s`,
					method: 'POST',
					body: { data: payload },
				};
			},
		}),
		createIncidentSection2: builder.mutation<
			IVehicleIncidentSection2,
			{ id: number }
		>({
			query(payload) {
				return {
					url: `/incident-section-2s`,
					method: 'POST',
					body: { data: payload },
				};
			},
		}),
		createIncidentSection3: builder.mutation<
			IVehicleCreateIncidentSection3,
			IVehicleCreateIncidentSection3Payload
		>({
			query(payload) {
				return {
					url: `/incident-section-3s`,
					method: 'POST',
					body: { data: payload },
				};
			},
		}),
		createIncidentSection4: builder.mutation<
			IVehicleCreateIncidentSection4,
			IVehicleCreateIncidentSection4Payload
		>({
			query(payload) {
				return {
					url: `/incident-section-4s`,
					method: 'POST',
					body: { data: payload },
				};
			},
		}),
		createIncidentSection5: builder.mutation<
			IVehicleCreateIncidentSection5,
			IVehicleCreateIncidentSection5Payload
		>({
			query(payload) {
				return {
					url: `/incident-section-5s`,
					method: 'POST',
					body: { data: payload },
				};
			},
		}),
		createIncidentSection7: builder.mutation<
			IVehicleCreateIncidentSection7,
			IVehicleCreateIncidentSection7Payload
		>({
			query(payload) {
				return {
					url: `/incident-section-7s`,
					method: 'POST',
					body: { data: payload },
				};
			},
		}),
		updateIncidentSection1: builder.mutation<
			void,
			IVehicleIncidentSection1Payload
		>({
			query(payload) {
				return {
					url: `/incident-section-1s/${payload.id}`,
					method: 'PUT',
					body: { data: payload },
				};
			},
		}),
		getIncidentSection2: builder.mutation<
			{ data: IVehicleIncidentSection2 },
			number
		>({
			query(incident_id) {
				return {
					url: `/incident-section-2s/${incident_id}`,
					method: 'GET',
				};
			},
		}),
		updateIncidentSection2: builder.mutation<
			void,
			IVehicleIncidentSection2Payload
		>({
			query(payload) {
				return {
					url: `/incident-section-2s/${payload.id}`,
					method: 'PUT',
					body: { data: payload },
				};
			},
		}),
		getIncidentSection3: builder.mutation<
			{ data: IVehicleIncidentSection3Payload },
			number
		>({
			query(incident_id) {
				return {
					url: `/incident-section-3s/${incident_id}`,
					method: 'GET',
				};
			},
		}),
		updateIncidentSection3: builder.mutation<
			void,
			IVehicleIncidentSection3Payload
		>({
			query(payload) {
				return {
					url: `/incident-section-3s/${payload.id}`,
					method: 'PUT',
					body: { data: payload },
				};
			},
		}),
		getIncidentSection4: builder.mutation<
			{ data: IVehicleIncidentSection4Payload },
			number
		>({
			query(incident_id) {
				return {
					url: `/incident-section-4s/${incident_id}`,
					method: 'GET',
				};
			},
		}),
		updateIncidentSection4FileUpload: builder.mutation<
			void,
			{ data: FormData }
		>({
			query(payload) {
				return {
					url: `/upload`,
					method: 'POST',
					body: payload.data,
				};
			},
		}),
		deleteIncidentSection4FileUpload: builder.mutation<void, number>({
			query(payload) {
				return {
					url: `/upload/files/${payload}`,
					method: 'DELETE',
				};
			},
		}),
		updateIncidentSection4: builder.mutation<
			void,
			IVehicleIncidentSection4Payload
		>({
			query(payload) {
				return {
					url: `/incident-section-4s/${payload.id}`,
					method: 'PUT',
					body: { data: payload },
				};
			},
		}),
		getIncidentSection5: builder.mutation<
			{ data: IVehicleIncidentSection5 },
			number
		>({
			query(incident_id) {
				return {
					url: `/incident-section-5s/${incident_id}`,
					method: 'GET',
				};
			},
		}),
		updateIncidentSection5: builder.mutation<
			void,
			IVehicleIncidentSection5 | { id: number; isReportFinished: true }
		>({
			query(payload) {
				return {
					url: `/incident-section-5s/${payload.id}`,
					method: 'PUT',
					body: { data: payload },
				};
			},
		}),
		getIncidentSection7: builder.mutation<
			{ data: IVehicleIncidentSection7 },
			number
		>({
			query(incident_id) {
				return {
					url: `/incident-section-7s/${incident_id}`,
					method: 'GET',
				};
			},
		}),
		updateIncidentSection7: builder.mutation<
			void,
			IVehicleIncidentSection7 | { id: number; }
		>({
			query(payload) {
				return {
					url: `/incident-section-7s/${payload.id}`,
					method: 'PUT',
					body: { data: payload },
				};
			},
		}),
		deleteIncidentActivity: builder.mutation<void, number>({
			query(activityId) {
				return {
					url: `/incident-activities/${activityId}`,
					method: 'DELETE',
				};
			},
		}),
		updateIncidentActivity: builder.mutation<
			void,
			{ activityId: number; content: string }
		>({
			query(payload) {
				return {
					url: `/incident-activities/${payload.activityId}`,
					method: 'PUT',
					body: payload,
				};
			},
		}),
		getBreakdownByCategory: builder.mutation<
			IIncidentCategoryBreakdown,
			IIncidentCategoryBreakdownFilters
		>({
			query(filters) {
				// @ts-ignore
				const urlString = new URLSearchParams(filters).toString();

				return {
					url: `/incidents/category-breakdown?${urlString}`,
					method: 'GET',
				};
			},
		}),
	}),
});

export const {
	useGetBranchesQuery,
	useGetVehicleIncidentsMutation,
	useGetIncidentMutation,
	useGetAllIncidentSectionsQuery,
	useAssignIncidentMutation,
	useGetIncidentActivityMutation,
	useCreateIncidentActivityMutation,
	useCreateIncidentMutation,
	useUpdateIncidentMutation,
	useGetIncidentSection1Mutation,
	useCreateIncidentSection1Mutation,
	useUpdateIncidentSection1Mutation,
	useGetIncidentSection2Mutation,
	useUpdateIncidentSection2Mutation,
	useGetIncidentSection3Mutation,
	useUpdateIncidentSection3Mutation,
	useGetIncidentSection4Mutation,
	useUpdateIncidentSection4Mutation,
	useUpdateIncidentSection4FileUploadMutation,
	useDeleteIncidentSection4FileUploadMutation,
	useGetIncidentSection5Mutation,
	useUpdateIncidentSection5Mutation,
	useGetIncidentSection7Mutation,
	useUpdateIncidentSection7Mutation,
	useCreateIncidentSection2Mutation,
	useCreateIncidentSection3Mutation,
	useCreateIncidentSection4Mutation,
	useCreateIncidentSection5Mutation,
	useUpdateIncidentActivityMutation,
	useDeleteIncidentActivityMutation,
	useUploadFileMutation,
	useGetBreakdownByCategoryMutation,
} = inventoryAPI;
