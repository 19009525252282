export const formatTenure = (value: string | undefined | null) => {
	switch (value) {
		case '0-3m':
			return '0 - 3 months';
		case '3-6m':
			return '3 - 6 months';
		case '6-12m':
			return '6 - 12 months';
		case '12-24m':
			return '1 - 2 years';
		case '2y-5y':
			return '2 - 5 years';
		case '5y+':
			return '5 or more years';
		default:
			return 'No tenure';
	}
};
