import React from 'react';

export interface IText {
	type?: 'default' | 'bold';
	color?: string;
	children: string | React.ReactNode;
	className?: string;
}

const Text: React.FC<IText> = ({
	type = 'default',
	color = 'text-secondary',
	children,
	className = '',
}) => {
	const textStyles = {
		default: `font-inter font-normal tracking-normal ${color}`,
		bold: `font-inter font-semibold tracking-normal ${color}`,
	};

	return <p className={`${textStyles[type]} ${className}`}>{children}</p>;
};

export default Text;
