import React, { useEffect, useRef, useState, TransitionEvent } from 'react';

export interface ISlideDown {
	open: boolean;
	children: React.ReactNode;
	className?: string;
}

const SlideDown: React.FC<ISlideDown> = ({
	open,
	children,
	className = '',
}) => {
	const ref = useRef(null);
	const [isOpen, setIsOpen] = useState(false);
	const [overflow, setOverflow] = useState('hidden');

	useEffect(() => {
		setIsOpen(open);
	}, [open]);

	useEffect(() => {
		if (!ref) {
			return;
		}
		const element: any = ref.current;
		element.style.overflow = overflow;
	}, [overflow, ref]);

	useEffect(
		() => {
			if (open) {
				handleOpen();
			} else {
				handleClose();
			}
		},
		// eslint-disable-next-line
		[isOpen]
	);

	const handleClose = () => {
		const element: any = ref.current;
		element.style.height = getComputedStyle(element).height;
		element.style.transition = 'height .3s ease-in-out';
		// eslint-disable-next-line
		const forceRepaint = element.offsetHeight; // force repaint
		element.style.height = '0px';
		element.style.overflow = 'hidden';
	};

	const handleOpen = () => {
		const element: any = ref.current;
		const prevHeight = element.style.height;
		element.style.height = 'auto';
		const endHeight = getComputedStyle(element).height;
		element.style.height = prevHeight;
		// eslint-disable-next-line
		const forceRepaint = element.offsetHeight; // force repaint
		element.style.transition = 'height .3s ease-in-out';
		element.style.height = endHeight;

		element.addEventListener(
			'transitionend',
			function transitionEnd(event: TransitionEvent<HTMLDivElement>) {
				if (event.propertyName === 'height' && isOpen) {
					setOverflow('inherit');

					element.style.transition = '';
					element.style.height = 'auto';
					element.removeEventListener('transitionend', transitionEnd, false);
				}
			},
			false
		);
	};

	return (
		<div
			className={`react-slidedown overflow-hidden ${className}`}
			ref={ref}
			style={{ height: 0 }}
		>
			{children}
		</div>
	);
};

export default SlideDown;
