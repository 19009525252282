import { Fragment, useState, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';

export interface IWarningPopup {
	showModal?: boolean;
	header?: string;
	content: string;
	setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
	handleDeleteItem(id: number): void;
	id: number;
}

const WarningPopup: React.FC<IWarningPopup> = ({
	showModal,
	header,
	content,
	setShowModal,
	handleDeleteItem,
	id,
}) => {
	const [show, setShow] = useState(showModal);
	const hidePopupStyle = show ? '' : 'hidden';

	useEffect(() => {
		setShow(showModal);
	}, [showModal]);

	const handleDeleteAction = () => {
		handleDeleteItem(id);
		setShow(false);
	};

	return (
		<>
			<Transition
				show={show}
				as={Fragment}
				enter="transform ease-out duration-300 transition"
				enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
				enterTo="translate-y-0 opacity-100 sm:translate-x-0"
				leave="transition ease-in duration-100"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"
			>
				<div
					className={`absolute -top-5 right-4 pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 ${hidePopupStyle}`}
				>
					<div className="p-4">
						<div className="flex items-start">
							<div className="ml-3 w-0 flex-1 pt-0.5">
								{header && (
									<p className="text-sm font-medium text-gray-900">{header}</p>
								)}
								<p className="mt-1 text-sm text-secondary-500">{content}</p>
								<div className="mt-3 flex space-x-7">
									<button
										type="button"
										className="rounded-md bg-white text-sm font-medium text-primary"
										onClick={handleDeleteAction}
									>
										Yes
									</button>
									<button
										type="button"
										className="rounded-md bg-white text-sm font-medium text-gray-700 hover:text-gray-500"
										onClick={() => {
											setShowModal(false);
										}}
									>
										No
									</button>
								</div>
							</div>
							<div className="ml-4 flex flex-shrink-0">
								<button
									type="button"
									className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500"
									onClick={() => {
										setShowModal(false);
									}}
								>
									<span className="sr-only">Close</span>
									<XIcon className="h-5 w-5" aria-hidden="true" />
								</button>
							</div>
						</div>
					</div>
				</div>
			</Transition>
		</>
	);
};

export default WarningPopup;
