import React, { useEffect } from 'react';
import { RouteProps, Navigate, Outlet } from 'react-router-dom';
import { useAppDispatch } from 'redux/hooks';
import { useGetUserMutation } from 'redux/api/authAPI';
import { loadUser } from 'redux/slices/userSlice';

const ProtectedRoute: React.FC<RouteProps> = () => {
	const localToken = localStorage.getItem('accessToken');
	const dispatch = useAppDispatch();
	const [getUser, { data, isLoading, isError, isSuccess }] =
		useGetUserMutation();

	useEffect(() => {
		if (isSuccess && data) {
			dispatch(loadUser(data));
		}
	}, [isSuccess, data, dispatch]);

	useEffect(() => {
		if (localToken) {
			getUser(localToken);
		}
	}, [localToken, getUser]);

	if (!localToken) return <Navigate to="/" />;

	if (isError) {
		localStorage.removeItem('accessToken');
		return <Navigate to="/" />;
	}

	if (isLoading) return <></>;

	if (isSuccess && localToken && data) return <Outlet />;

	return <></>;
};

export default ProtectedRoute;
